import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postNewMaintenanceLog = async (newMaintenanceInfo) => {
  try {
    const formData = new FormData();
    formData.append('title', newMaintenanceInfo.title);
    formData.append('deviceId', newMaintenanceInfo.deviceData.id);
    formData.append('workerId', newMaintenanceInfo.workerId);
    formData.append('serviceType', newMaintenanceInfo.serviceType);
    formData.append('timeZone', newMaintenanceInfo.timeZone);
    formData.append('reportDate', newMaintenanceInfo.reportDate);

    if (newMaintenanceInfo.dispatchDate)
      formData.append('dispatchDate', newMaintenanceInfo.dispatchDate);

    if (newMaintenanceInfo.issueDescription)
      formData.append('issueDescription', newMaintenanceInfo.issueDescription);

    if (newMaintenanceInfo.actionTaken)
      formData.append('actionTaken', newMaintenanceInfo.actionTaken);

    if (newMaintenanceInfo.description)
      formData.append('description', newMaintenanceInfo.description);

    if (newMaintenanceInfo.maintenanceImage.length) {
      newMaintenanceInfo.maintenanceImage.forEach((eachImageData) => {
        formData.append(
          'maintenanceImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }

    const res = await axios.post('edmu/device/maintenance-log', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllMaintenanceLog = async (requestInfo) => {
  const res = await axios.get('edmu/device/maintenance-log', {
    params: {
      'device-id': requestInfo?.deviceId || null,
      'report-date[start]': requestInfo?.reportStartDate || null,
      'report-date[end]': requestInfo?.reportEndDate || null,
      'dispatch-date[start]': requestInfo?.dispatchStartDate || null,
      'dispatch-date[end]': requestInfo?.dispatchEndDate || null,
      'time-zone': requestInfo?.timeZone || null,
      'sort-type': requestInfo?.sortType || null,
      limit: requestInfo?.limit || null,
    },
  });
  return res.data;
};

export const getMaintenanceLogById = async (requestInfo) => {
  const res = await axios.get('edmu/device/maintenance-log', {
    params: {
      id: requestInfo?.logId,
      'device-id': requestInfo?.deviceId || null,
      'report-date[start]': requestInfo?.reportStartDate || null,
      'report-date[end]': requestInfo?.reportEndDate || null,
      'dispatch-date[start]': requestInfo?.dispatchStartDate || null,
      'dispatch-date[end]': requestInfo?.dispatchEndDate || null,
      'time-zone': requestInfo?.timeZone || null,
      'sort-type': requestInfo?.sortType || null,
      limit: requestInfo?.limit || null,
    },
  });
  return res.data;
};

export const patchMaintenanceLog = async (modifyInfo) => {
  const res = await axios.patch(
    `edmu/device/maintenance-log?id=${modifyInfo.id}`,
    { ...modifyInfo.data, timeZone: modifyInfo.timeZone },
  );
  return res;
};

export const deleteMaintenanceLog = async (logId) => {
  const res = await axios.delete(`edmu/device/maintenance-log?id=${logId}`);
  return res;
};

export const postMaintenanceImage = async (dataInfo) => {
  const formData = new FormData();
  if (dataInfo.images?.length) {
    dataInfo.images.forEach((eachImageData) => {
      formData.append(
        'image',
        imageToBlob(eachImageData.image),
        eachImageData.name,
      );
    });
  }

  const res = await axios.post(
    `edmu/image/device-maintenance-log?type-id=${dataInfo.logId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res;
};

export const deleteMaintenanceImage = async (imageId) => {
  const res = await axios.delete(`edmu/image?id=${imageId}`);
  return res;
};
