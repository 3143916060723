import { makeAutoObservable, runInAction } from 'mobx';
import {
  postNewInspectionHistory,
  getAllInspectionHistories,
  getInspectionHistoryById,
  patchInspectionHistory,
  deleteInspectionHistory,
  postInspectionImage,
  deleteInspectionImage,
} from 'src/api/inspection';

class inspectionStore {
  accountStore;

  alertStore;

  inspectionHistories = [];

  inspectionInfo = {
    id: null,
    title: null,
    userId: null,
    deviceId: null,
    deviceData: {
      id: null,
      nickname: null,
      serialNumber: null,
      location: {
        id: null,
        name: null,
        type: null,
        address: null,
        addressDetail: null,
        lat: null,
        lon: null,
        zipCode: null,
      },
      type: {
        id: null,
        name: null,
      },
    },
    reporter: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countryCode: null,
      code: null,
    },
    worker: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countryCode: null,
      code: null,
    },
    maintenanceDate: null,
    description: null,
    images: [],
  };

  startIndex = 0;

  endIndex = 1;

  selectedMaintenanceDate = [null, null];

  constructor({ accountStore, alertStore }) {
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
  }

  initializeInspectionInfo = () => {
    this.inspectionInfo = {
      id: null,
      userId: null,
      title: null,
      deviceId: null,
      deviceData: {
        id: null,
        nickname: null,
        serialNumber: null,
        location: {
          id: null,
          name: null,
          type: null,
          address: null,
          addressDetail: null,
          lat: null,
          lon: null,
          zipCode: null,
        },
        type: {
          id: null,
          name: null,
        },
      },
      workerName: null,
      workerEmail: null,
      workerPhone: null,
      workerCountryCode: null,
      serviceType: null,
      reportDate: null,
      dispatchDate: null,
      issueDescription: null,
      actionTaken: null,
      description: null,
      images: [],
    };
  };

  createNewInspectionHistory = async (newHistory) => {
    try {
      const id = await postNewInspectionHistory(newHistory).then((res) => {
        this.alertStore.setAlertOpen(
          'success',
          '새로운 점검 이력 추가가 완료되었습니다.',
        );
        if (res.code) throw res;
        return true;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getInspectionHistories = async (requestInfo) => {
    try {
      let res;
      if (requestInfo?.historyId) {
        res = await getInspectionHistoryById({
          ...requestInfo,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.inspectionInfo = res?.[0];
        });
      } else {
        res = await getAllInspectionHistories({
          ...requestInfo,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.inspectionHistories = res;
        });
      }
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateInspectionHistory = async (modifyInfo, refresh = true) => {
    try {
      await patchInspectionHistory({
        ...modifyInfo,
        timeZone: this.accountStore.account.timeZone,
      }).then((res) => {
        if (res) {
          if (refresh) {
            this.getInspectionHistories({
              start: this.selectedMaintenanceDate[this.startIndex],
              end: this.selectedMaintenanceDate[this.endIndex],
            });
          }
          this.alertStore.setAlertOpen('success', '이력 수정이 완료되었습니다');
        }
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteInspectionHistoryById = async (selectedMaintenanceLogs) => {
    try {
      const deleteMaintenanceLogs = selectedMaintenanceLogs.map(
        (manufacturerInfo) => {
          return deleteInspectionHistory(manufacturerInfo.id);
        },
      );
      return Promise.all(deleteMaintenanceLogs)
        .then(() => {
          this.getInspectionHistories();
          this.alertStore.setAlertOpen(
            'success',
            `정기점검 이력 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewInspectionImage = async (historyInfo) => {
    try {
      const id = await postInspectionImage(historyInfo).then((res) => {
        this.alertStore.setAlertOpen('success', '사진 추가가 완료되었습니다');
        if (res.code) throw res;
        return true;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteInspectionImageById = async (selectedMaintenanceImages) => {
    try {
      deleteInspectionImage(selectedMaintenanceImages).then(() => {
        // this.getInspectionHistories();
        this.alertStore.setAlertOpen('success', `사진 삭제를 완료했습니다`);
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  setSelectedReportDate = (date) => {
    this.selectedMaintenanceDate = date;
  };
}

export default inspectionStore;
