import { styled, Box } from '@mui/material';

export const InfoA = styled(`a`)(
  ({ theme }) => `
    background: ${theme.colors.primary.main} !important;
    border-radius: 6px !important;
    font-weight: 500 !important;
        `,
);

export const InfoP = styled(`a`)(
  ({ theme }) => `
    color: ${theme.colors.primary.main};
    // background: transparent !important;
    border-radius: 6px !important;
    font-weight: 500 !important;
        `,
);

export const InfoLanguageSwitcherWrapper = styled(Box)(
  () => `
  .MuiIconButton-root{
    float: right;
    margin: 5px;
  }
  padding: 5px;
  margin-left: 10px;
`,
);

export const HeaderLogoWrapper = styled(Box)(
  () => `
  margin-top: -0.5rem;
`,
);
