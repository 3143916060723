import { makeAutoObservable } from 'mobx';
import { deleteImageApi } from 'src/api/image';
import {
  deleteItemApi,
  deleteItemCountryApi,
  deleteItemTypeApi,
  deleteItemUnitApi,
  getAllItemsApi,
  getContactApi,
  getItemCountryApi,
  getItemInfoApi,
  getItemTypeApi,
  getItemUnitApi,
  getAllReceivingPlanningApi,
  patchItemApi,
  postItemApi,
  postItemCountryApi,
  postItemTypeApi,
  postItemUnitApi,
  postReceivingPlanningApi,
  getReceivingPlanningInfoApi,
  patchReceivingPlanningApi,
  deleteReceivingPlanningApi,
  completeReceivingPlanningApi,
  cancelReceivingPlanningApi,
  getAllReceivingHistoryApi,
  getReceivingHistoryInfoApi,
  deleteReceivingHistoryApi,
  postShippingPlanningApi,
  getAllShippingPlanningApi,
  getShippingPlanningInfoApi,
  patchShippingPlanningApi,
  completeShippingPlanningApi,
  cancelShippingPlanningApi,
  deleteShippingPlanningApi,
  getShippingHistoryInfoApi,
  getAllShippingHistoryApi,
  deleteShippingHistoryApi,
  getBomInfoApi,
  postBomInfoApi,
  deleteBomListApi,
  patchBomInfoApi,
  postBomItemApi,
  patchBomItemApi,
  deleteBomItemApi,
  postReceivingPlanningItemApi,
  patchReceivingPlanningItemApi,
  deleteReceivingPlanningItemApi,
  postShippingPlanningItemApi,
  deleteShippingPlanningItemApi,
  patchShippingPlanningItemApi,
  postManufacturingPlanningApi,
  getAllManufacturingPlanningApi,
  getManufacturingPlanningInfoApi,
  patchManufacturingPlanningBomApi,
  postManufacturingPlanningBomApi,
  deleteManufacturingPlanningItemApi,
  patchManufacturingPlanningApi,
  completeManufacturingPlanningApi,
  cancelManufacturingPlanningApi,
  deleteManufacturingPlanningApi,
  getManufacturingHistoryInfoApi,
  getAllManufacturingHistoryApi,
  deleteManufacturingHistoryApi,
  postIncominfgPlanItemLotApi,
  patchIncominfgPlanItemLotApi,
  deleteIncominfgPlanItemLotApi,
  getLotListApi,
  patchOutgoingPlanItemLotApi,
  postOutgoingPlanItemLotApi,
  getOutgoingPlanItemLotListApi,
  getStockListApi,
  postStockAdjustmentApi,
  getStockAdjustmentHistoryApi,
  getStockTraceApi,
  postMesItemDetailApi,
  deleteMesItemDetailApi,
  patchMesItemDetailApi,
  getDefaultDetailKeyApi,
} from 'src/api/mes';

class mesStore {
  alertStore;

  shippingPlanningList = [];

  shippingHistoryList = [];

  manufacturingList = [];

  receivingList = [];

  items = [];

  itemType = [];

  itemUnit = [];

  itemCountry = [];

  bomList = [];

  lotList = [];

  lotInfo = {
    id: null,
    lotNumber: null,
    quantity: null,
    productionDate: null,
    createdAt: null,
    updatedAt: null,
    itemData: {
      id: null,
      name: null,
      code: null,
      unit: null,
      itemType: null,
    },
    assignedQuantity: null,
    assignableQuantity: null,
  };

  outgoingLotList = [];

  newBomInfo;

  newBomList = [];

  editBomList = [];

  deleteBomList = [];

  deleteBomItemList = [];

  contacts = [];

  stockList = [];

  stockTraceList = [];

  stockAdjustmentHistoryList = [];

  itemDetailDefaultKeyList = [];

  startIndex = 0;

  endIndex = 1;

  selectedStartDate = [null, null];

  selectedDueDate = [null, null];

  selectedCompleteDate = [null, null];

  shippingPlanningInfo = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    shippingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    shippingItemLotData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  shippingHistoryInfo = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    completeDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    shippingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    shippingItemLotData: [
      {
        createdAt: null,
        id: null,
        itemId: null,
        lotData: { id: null, lotNumber: null },
        quantity: null,
        shippingHistoryId: null,
        shippingItemHistoryId: null,
        updatedAt: null,
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  manufacturingPlanningInfo = {
    id: '',
    code: '',
    planState: '',
    dueDate: '',
    quantity: '',
    totalPrice: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    itemData: {
      id: '',
      name: '',
      code: '',
      unit: '',
      itemType: '',
    },
    workerData: {
      id: '',
      name: '',
      code: '',
    },
    bomItems: [
      {
        id: '',
        quantity: '',
        cost: '',
        description: '',
        createdAt: '',
        updatedAt: '',
        itemData: {
          id: '',
          name: '',
          code: '',
          cost: '',
          unit: '',
          itemType: '',
        },
      },
      {
        id: '',
        quantity: '',
        cost: '',
        description: '',
        createdAt: '',
        updatedAt: '',
        itemData: {
          id: '',
          name: '',
          code: '',
          cost: '',
          unit: '',
          itemType: '',
        },
      },
    ],
    manufacturingItemLotData: [
      {
        id: '',
        manufacturingId: '',
        manufacturingBomId: '',
        itemId: '',
        lotNumber: '',
        quantity: '',
        type: '',
        createdAt: '',
        updatedAt: '',
        lotData: {
          id: '',
          lotNumber: '',
          quantity: '',
        },
      },
    ],
    originalBom: {
      id: '',
      name: '',
      description: '',
    },
  };

  manufacturingHistoryInfo = {
    id: '',
    code: '',
    planState: '',
    dueDate: '',
    completeDate: '',
    quantity: '',
    totalPrice: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    itemData: {
      id: '',
      name: '',
      code: '',
      unit: '',
      itemType: '',
      country: '',
      cost: '',
      sellPrice: '',
      description: '',
      isDeleted: '',
    },
    workerData: {
      id: '',
      name: '',
      code: '',
      email: '',
      isDeleted: '',
    },
    bomData: {
      id: '',
      name: '',
      isDeleted: '',
    },
    bomItems: [
      {
        id: '',
        quantity: '',
        cost: '',
        description: '',
        createdAt: '',
        updatedAt: '',
        itemData: {
          id: '',
          name: '',
          code: '',
          unit: '',
          itemType: '',
          country: '',
          cost: '',
          sellPrice: '',
          description: '',
          isDeleted: '',
        },
      },
    ],
  };

  receivingPlanningInfo = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    receivingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    receivingItemLotData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  receivingHistoryInfo = {
    id: null,
    code: null,
    planState: null,
    startDate: null,
    dueDate: null,
    completeDate: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    receivingItemData: [
      {
        id: null,
        quantity: null,
        unitPrice: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          unit: null,
          itemType: null,
        },
      },
    ],
    receivingItemLotData: [
      {
        createdAt: null,
        id: null,
        itemId: null,
        lotData: { id: null, lotNumber: null },
        quantity: null,
        shippingHistoryId: null,
        shippingItemHistoryId: null,
        updatedAt: null,
      },
    ],
    workerData: {
      id: null,
      name: null,
      code: null,
    },
    contactData: {
      id: null,
      name: null,
      code: null,
    },
  };

  itemInfo = {
    id: null,
    code: null,
    name: null,
    model: null,
    itemType: {
      id: null,
      name: null,
    },
    unit: {
      id: null,
      name: null,
    },
    country: {
      id: null,
      name: null,
    },
    cost: null,
    sellPrice: null,
    description: null,
    image: {
      id: null,
      imageUrl: null,
      filePath: null,
    },
    itemDetail: [],
  };

  bomInfo = {
    id: null,
    name: null,
    totalPrice: null,
    description: null,
    createdAt: null,
    updatedAt: null,
    itemData: {
      id: null,
      name: null,
      code: null,
      cost: null,
      unit: null,
      itemType: null,
    },
    bomItems: [
      {
        id: null,
        quantity: null,
        description: null,
        createdAt: null,
        updatedAt: null,
        itemData: {
          id: null,
          name: null,
          code: null,
          cost: null,
          unit: null,
          itemType: null,
        },
      },
    ],
  };

  constructor({ alertStore, utilStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
    this.utilStore = utilStore;
  }

  initItemInfo = async () => {
    this.itemInfo = {
      id: null,
      code: null,
      name: null,
      model: null,
      itemType: {
        id: null,
        name: null,
      },
      unit: {
        id: null,
        name: null,
      },
      country: {
        id: null,
        name: null,
      },
      cost: null,
      sellPrice: null,
      description: null,
      image: {
        id: null,
        imageUrl: null,
        filePath: null,
      },
      itemDetail: [],
    };
  };

  setItemInfo = (target, value) => {
    this.itemInfo = { ...this.itemInfo, [target]: value };
  };

  setBomList = (bomList) => {
    this.bomList = bomList;
  };

  setNewBomInfo = (newBomInfo) => {
    this.newBomInfo = newBomInfo;
  };

  setNewBomList = (newBomList) => {
    this.newBomList = newBomList;
  };

  setDeleteBomList = (deleteBomList) => {
    this.deleteBomList = deleteBomList;
  };

  setDeleteBomItemList = (deleteBomItemList) => {
    this.deleteBomItemList = deleteBomItemList;
  };

  setEditBomList = (editBomList) => {
    this.editBomList = editBomList;
  };

  setSelectedStartDate = (date) => {
    this.selectedStartDate = date;
  };

  setSelectedDueDate = (date) => {
    this.selectedDueDate = date;
  };

  setSelectedCompleteDate = (date) => {
    this.selectedCompleteDate = date;
  };

  initShippingPlanningList = async () => {
    this.shippingPlanningList = [];
  };

  initShippingInfo = async () => {
    this.shippingPlanningInfo = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      shippingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initShippingHistoryInfo = async () => {
    this.shippingHistoryInfo = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      completeDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      shippingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initManufacturingList = async () => {
    this.manufacturingList = [];
  };

  initManufacturingInfo = async () => {
    this.manufacturingPlanningInfo = {
      id: '',
      code: '',
      planState: '',
      dueDate: '',
      quantity: '',
      totalPrice: '',
      description: '',
      createdAt: '',
      updatedAt: '',
      itemData: {
        id: '',
        name: '',
        code: '',
        unit: '',
        itemType: '',
      },
      workerData: {
        id: '',
        name: '',
        code: '',
      },
      bomItems: [
        {
          id: '',
          quantity: '',
          cost: '',
          description: '',
          createdAt: '',
          updatedAt: '',
          itemData: {
            id: '',
            name: '',
            code: '',
            cost: '',
            unit: '',
            itemType: '',
          },
        },
        {
          id: '',
          quantity: '',
          cost: '',
          description: '',
          createdAt: '',
          updatedAt: '',
          itemData: {
            id: '',
            name: '',
            code: '',
            cost: '',
            unit: '',
            itemType: '',
          },
        },
      ],
      originalBom: {
        id: '',
        name: '',
        description: '',
      },
    };
  };

  initManufacturingHistoryInfo = async () => {
    this.manufacturingHistoryInfo = {
      id: '',
      code: '',
      planState: '',
      dueDate: '',
      completeDate: '',
      quantity: '',
      totalPrice: '',
      description: '',
      createdAt: '',
      updatedAt: '',
      itemData: {
        id: '',
        name: '',
        code: '',
        unit: '',
        itemType: '',
        country: '',
        cost: '',
        sellPrice: '',
        description: '',
        isDeleted: '',
      },
      workerData: {
        id: '',
        name: '',
        code: '',
        email: '',
        isDeleted: '',
      },
      bomData: {
        id: '',
        name: '',
        isDeleted: '',
      },
      bomItems: [
        {
          id: '',
          quantity: '',
          cost: '',
          description: '',
          createdAt: '',
          updatedAt: '',
          itemData: {
            id: '',
            name: '',
            code: '',
            unit: '',
            itemType: '',
            country: '',
            cost: '',
            sellPrice: '',
            description: '',
            isDeleted: '',
          },
        },
      ],
    };
  };

  initReceivingList = async () => {
    this.receivingList = [];
  };

  initReceivingInfo = async () => {
    this.receivingPlanningInfo = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      receivingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initReceivingHistoryInfo = async () => {
    this.receivingHistoryInfo = {
      id: null,
      code: null,
      planState: null,
      startDate: null,
      dueDate: null,
      completeDate: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      receivingItemData: [
        {
          id: null,
          quantity: null,
          unitPrice: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            unit: null,
            itemType: null,
          },
        },
      ],
      workerData: {
        id: null,
        name: null,
        code: null,
      },
      contactData: {
        id: null,
        name: null,
        code: null,
      },
    };
  };

  initBomList = async () => {
    this.bomList = [];
  };

  initNewBomList = async () => {
    this.newBomList = [];
  };

  initEditBomList = () => {
    this.editBomList = [];
  };

  initDeleteBomList = () => {
    this.deleteBomList = [];
  };

  initDeleteBomItemList = () => {
    this.deleteBomItemList = [];
  };

  initBomInfo = async () => {
    this.bomInfo = {
      id: null,
      name: null,
      totalPrice: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      itemData: {
        id: null,
        name: null,
        code: null,
        cost: null,
        unit: null,
        itemType: null,
      },
      bomItems: [
        {
          id: null,
          quantity: null,
          description: null,
          createdAt: null,
          updatedAt: null,
          itemData: {
            id: null,
            name: null,
            code: null,
            cost: null,
            unit: null,
            itemType: null,
          },
        },
      ],
    };
  };

  createNewShipping = async (newShipping) => {
    try {
      const res = await postShippingPlanningApi({
        ...newShipping,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '주문_주문 계획 등록에 성공하였습니다',
      );
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllShippingPlanning = async (getShippingPlanningInfo) => {
    try {
      const res = await getAllShippingPlanningApi({
        ...getShippingPlanningInfo,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.shippingPlanningList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getShippingPlanningInfo = async (getShippingInfo) => {
    try {
      const res = await getShippingPlanningInfoApi(getShippingInfo);
      if (res.code) throw res;
      this.shippingPlanningInfo = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editShippingPlanningAndAddItems = async (
    shippingId,
    shippingItemData,
    shippingLotData,
  ) => {
    try {
      const editPlanningItemsRes = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const [
        eachDataIndex,
        eachItemData,
      ] of shippingItemData.entries()) {
        if (eachItemData.id) {
          if (eachItemData.editing) {
            editPlanningItemsRes.push(
              await patchShippingPlanningItemApi({
                id: eachItemData.id,
                itemId: shippingLotData[eachDataIndex]?.lotList?.length
                  ? eachItemData.itemData.id !==
                    eachItemData.itemData.existingItemId
                    ? eachItemData.itemData.id
                    : undefined
                  : eachItemData.itemData.id,
                unitPrice: eachItemData.unitPrice,
                quantity: eachItemData.quantity,
              }),
            );
          } else {
            editPlanningItemsRes.push(new Promise((resolve) => resolve(true)));
          }
        } else {
          editPlanningItemsRes.push(
            await postShippingPlanningItemApi({
              shippingId: shippingId,
              itemId: eachItemData.itemData.id,
              unitPrice: eachItemData.unitPrice,
              quantity: eachItemData.quantity,
            }).then((res) => {
              if (res.id && shippingLotData[eachDataIndex]?.lotList?.length) {
                return Promise.all(
                  shippingLotData[eachDataIndex].lotList.map((eachLotData) => {
                    return postOutgoingPlanItemLotApi({
                      type: 'shipping',
                      planId: shippingId,
                      planItemId: res.id,
                      itemId: shippingLotData[eachDataIndex].itemInfo.id,
                      quantity: eachLotData.quantity,
                      lotId: eachLotData.lotId,
                    });
                  }),
                );
              }
              return res;
            }),
          );
        }
      }

      return Promise.all(editPlanningItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteShippingPlanningItemById = async (deleteBomItemList) => {
    try {
      const deleteShippingPlanningItemRes = deleteBomItemList.map(
        (eachDeleteBomItemId) => {
          return deleteShippingPlanningItemApi(eachDeleteBomItemId);
        },
      );
      return Promise.all(deleteShippingPlanningItemRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateShippingPlanningInfo = async (modifyShippingInfo) => {
    try {
      const res = await patchShippingPlanningApi(modifyShippingInfo);

      if (modifyShippingInfo.deleteLotList.length) {
        const deleteBomRes = await this.deleteLotListById(
          modifyShippingInfo.deleteLotList,
          'shipping',
        );
        if (deleteBomRes !== true) throw deleteBomRes;
      }

      if (modifyShippingInfo.shippingItemData.length) {
        const editItemRes = await this.editShippingPlanningAndAddItems(
          modifyShippingInfo.id,
          modifyShippingInfo.shippingItemData,
          modifyShippingInfo.shippingLotData,
        );
        if (editItemRes !== true) throw editItemRes;
      }

      if (modifyShippingInfo.deleteItemList.length) {
        const dleteBomItemRes = await this.deleteShippingPlanningItemById(
          modifyShippingInfo.deleteItemList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      if (modifyShippingInfo.shippingLotData.length) {
        const editLotRes = await this.editOutgoingPlanItemLot({
          shippingLotData: modifyShippingInfo.shippingLotData,
          planId: modifyShippingInfo.id,
        });
        if (editLotRes !== true) throw editLotRes;
      }

      if (modifyShippingInfo.isComplete) {
        const completeRes = await completeShippingPlanningApi({
          ...modifyShippingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (completeRes !== 'OK') throw completeRes;
      } else if (modifyShippingInfo.isCanceled) {
        const cancelRes = await cancelShippingPlanningApi({
          ...modifyShippingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (cancelRes !== 'OK') throw cancelRes;
      }

      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '주문_주문 계획 수정에 성공하였습니다',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteShippingPlanningById = async (selectedShippingList) => {
    try {
      const deleteItemTasks = selectedShippingList.map((itemInfo) => {
        return deleteShippingPlanningApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllShippingPlanning();
          this.alertStore.setAlertOpen(
            'success',
            '주문_주문 계획 삭제를 완료했습니다',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getShippingHistoryInfo = async (getShippingInfo) => {
    try {
      const res = await getShippingHistoryInfoApi(getShippingInfo);
      if (res.code) throw res;
      this.shippingHistoryInfo = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllShippingHistory = async (getShippingHistory) => {
    try {
      const res = await getAllShippingHistoryApi({
        ...getShippingHistory,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.shippingHistoryList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteShippingHistoryById = async (
    selectedShippingList,
    onlyHistory = false,
  ) => {
    try {
      const deleteItemTasks = selectedShippingList.map((itemInfo) => {
        return deleteShippingHistoryApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllShippingHistory();
          this.alertStore.setAlertOpen(
            'success',
            `${
              onlyHistory ? '출고_출고' : '주문_주문'
            } 이력 삭제를 완료했습니다`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewManufacturing = async (newManufacturing) => {
    try {
      const res = await postManufacturingPlanningApi({
        ...newManufacturing,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '생산_생산 계획 등록에 성공하였습니다',
      );
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllManufacturingPlanning = async (getManufacturingPlanningInfo) => {
    try {
      const res = await getAllManufacturingPlanningApi({
        ...getManufacturingPlanningInfo,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.manufacturingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getManufacturingPlanningInfo = async (getManufacturingInfo) => {
    try {
      const res = await getManufacturingPlanningInfoApi(getManufacturingInfo);
      if (res.code) throw res;
      this.manufacturingPlanningInfo = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editManufacturingPlanningAndAddBom = async (
    manufacturingId,
    bomData,
    manufacturingLotData,
  ) => {
    try {
      const editPlanningItemsRes = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const [eachDataIndex, eachBomData] of bomData.entries()) {
        if (eachBomData.id) {
          if (eachBomData.editing) {
            editPlanningItemsRes.push(
              await patchManufacturingPlanningBomApi({
                id: eachBomData.id,
                itemId: manufacturingLotData[eachDataIndex]?.lotList?.length
                  ? eachBomData.itemData.id !==
                    eachBomData.itemData.existingItemId
                    ? eachBomData.itemData.id
                    : undefined
                  : eachBomData.itemData.id,
                cost: eachBomData.cost,
                quantity: eachBomData.quantity,
                description: eachBomData.description,
              }),
            );
          } else {
            editPlanningItemsRes.push(new Promise((resolve) => resolve(true)));
          }
        } else {
          editPlanningItemsRes.push(
            await postManufacturingPlanningBomApi({
              manufacturingId: manufacturingId,
              itemId: eachBomData.itemData.id,
              cost: eachBomData.cost,
              quantity: eachBomData.quantity,
              description: eachBomData.description,
            }).then((res) => {
              if (
                res.id &&
                manufacturingLotData[eachDataIndex]?.lotList?.length
              ) {
                return Promise.all(
                  manufacturingLotData[eachDataIndex].lotList.map(
                    (eachLotData) => {
                      return postOutgoingPlanItemLotApi({
                        type: 'manufacturing',
                        planId: manufacturingId,
                        planItemId: res.id,
                        itemId: manufacturingLotData[eachDataIndex].itemInfo.id,
                        quantity: eachLotData.quantity,
                        lotId: eachLotData.lotId,
                      });
                    },
                  ),
                );
              }
              return res;
            }),
          );
        }
      }

      return Promise.all(editPlanningItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteManufacturingPlanningBomById = async (deleteBomList) => {
    try {
      const deleteManufacturingPlanningBomRes = deleteBomList.map(
        (eachDeleteBomId) => {
          return deleteManufacturingPlanningItemApi(eachDeleteBomId);
        },
      );
      return Promise.all(deleteManufacturingPlanningBomRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editManufacturingPlanItemLot = async (editLotInfo) => {
    try {
      const addOrEditLotRes = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (let eachLotInfo of editLotInfo.manufacturingLotData) {
        // eslint-disable-next-line no-restricted-syntax
        for await (let eachInfoLotList of eachLotInfo.lotList) {
          if (eachInfoLotList.editing) {
            if (eachInfoLotList.id) {
              addOrEditLotRes.push(
                await patchOutgoingPlanItemLotApi({
                  type: 'manufacturing',
                  id: eachInfoLotList.id,
                  quantity: eachInfoLotList.quantity,
                  lotId: eachInfoLotList.lotId,
                }),
              );
            } else if (eachLotInfo.planItemId) {
              addOrEditLotRes.push(
                await postOutgoingPlanItemLotApi({
                  type: 'manufacturing',
                  planId: editLotInfo.planId,
                  planItemId: eachLotInfo.planItemId,
                  itemId: eachLotInfo.itemInfo.id,
                  quantity: eachInfoLotList.quantity,
                  lotId: eachInfoLotList.lotId,
                }),
              );
            }
          } else {
            addOrEditLotRes.push(new Promise((resolve) => resolve(true)));
          }
        }
      }
      return Promise.all(addOrEditLotRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateManufacturingPlanningInfo = async (modifyManufacturingInfo) => {
    try {
      const res = await patchManufacturingPlanningApi(modifyManufacturingInfo);

      if (modifyManufacturingInfo.deleteLotList?.length) {
        const deleteBomRes = await this.deleteLotListById(
          modifyManufacturingInfo.deleteLotList,
          'manufacturing',
        );
        if (deleteBomRes !== true) throw deleteBomRes;
      }

      if (
        !modifyManufacturingInfo.initManufacturingLotNumber &&
        modifyManufacturingInfo.manufacturingLotInfo?.lotNumber
      ) {
        const createManufacturingLotRes = await postIncominfgPlanItemLotApi({
          type: 'manufacturing',
          planId: modifyManufacturingInfo.id,
          planItemId: undefined,
          itemId: modifyManufacturingInfo.itemData.id,
          quantity: modifyManufacturingInfo.data.quantity,
          lotNumber: modifyManufacturingInfo.manufacturingLotInfo.lotNumber,
        });
        if (createManufacturingLotRes.code) throw createManufacturingLotRes;
      }

      if (
        (modifyManufacturingInfo.initManufacturingLotNumber &&
          modifyManufacturingInfo.manufacturingLotInfo?.lotNumber &&
          modifyManufacturingInfo.initManufacturingLotNumber !==
            modifyManufacturingInfo.manufacturingLotInfo?.lotNumber) ||
        (modifyManufacturingInfo.initManufacturingLotNumber &&
          modifyManufacturingInfo.data?.quantity !==
            modifyManufacturingInfo.initQuantity)
      ) {
        const updateManufacturingLotRes = await patchIncominfgPlanItemLotApi({
          type: 'manufacturing',
          id: modifyManufacturingInfo.manufacturingLotInfo.id,
          quantity: modifyManufacturingInfo.data.quantity,
          lotNumber: modifyManufacturingInfo.manufacturingLotInfo.lotNumber,
        });
        if (updateManufacturingLotRes.code) throw updateManufacturingLotRes;
      }

      if (modifyManufacturingInfo.bomData?.length) {
        const editBomRes = await this.editManufacturingPlanningAndAddBom(
          modifyManufacturingInfo.id,
          modifyManufacturingInfo.bomData,
          modifyManufacturingInfo.manufacturingLotData,
        );
        if (editBomRes !== true) throw editBomRes;
      }

      if (modifyManufacturingInfo.deleteBomList?.length) {
        const dleteBomItemRes = await this.deleteManufacturingPlanningBomById(
          modifyManufacturingInfo.deleteBomList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      if (modifyManufacturingInfo.manufacturingLotData?.length) {
        const editLotRes = await this.editManufacturingPlanItemLot({
          manufacturingLotData: modifyManufacturingInfo.manufacturingLotData,
          planId: modifyManufacturingInfo.id,
        });
        if (editLotRes !== true) throw editLotRes;
      }

      if (modifyManufacturingInfo.isComplete) {
        const completeRes = await completeManufacturingPlanningApi({
          ...modifyManufacturingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (completeRes !== 'OK') throw completeRes;
      } else if (modifyManufacturingInfo.isCanceled) {
        const cancelRes = await cancelManufacturingPlanningApi({
          ...modifyManufacturingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (cancelRes !== 'OK') throw cancelRes;
      }

      if (res.code) throw res;

      this.alertStore.setAlertOpen(
        'success',
        '생산_생산 계획 수정에 성공하였습니다',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteManufacturingPlanningById = async (selectedManufacturingList) => {
    try {
      const deleteItemTasks = selectedManufacturingList.map((itemInfo) => {
        return deleteManufacturingPlanningApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllManufacturingPlanning();
          this.alertStore.setAlertOpen(
            'success',
            '생산_생산 계획 삭제를 완료했습니다',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getManufacturingHistoryInfo = async (getManufacturingInfo) => {
    try {
      const res = await getManufacturingHistoryInfoApi(getManufacturingInfo);
      if (res.code) throw res;
      this.manufacturingHistoryInfo = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllManufacturingHistory = async (getManufacturingHistory) => {
    try {
      const res = await getAllManufacturingHistoryApi({
        ...getManufacturingHistory,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.manufacturingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteManufacturingHistoryById = async (selectedManufacturingList) => {
    try {
      const deleteItemTasks = selectedManufacturingList.map((itemInfo) => {
        return deleteManufacturingHistoryApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllManufacturingHistory();
          this.alertStore.setAlertOpen(
            'success',
            '생산_생산 이력 삭제를 완료했습니다',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewReceiving = async (newReceiving) => {
    try {
      const res = await postReceivingPlanningApi({
        ...newReceiving,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '입고_입고 계획 등록에 성공하였습니다',
      );
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllReceivingPlanning = async (getReceivingPlanningInfo) => {
    try {
      const res = await getAllReceivingPlanningApi({
        ...getReceivingPlanningInfo,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.receivingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getReceivingPlanningInfo = async (getReceivingInfo) => {
    try {
      const res = await getReceivingPlanningInfoApi(getReceivingInfo);
      if (res.code) throw res;
      this.receivingPlanningInfo = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editReceivingPlanningItems = async (
    receivingId,
    receivingItemData,
    receivingLotData,
  ) => {
    try {
      const editPlanningItemsRes = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const [
        eachDataIndex,
        eachItemData,
      ] of receivingItemData.entries()) {
        if (eachItemData.id) {
          if (eachItemData.editing) {
            editPlanningItemsRes.push(
              await patchReceivingPlanningItemApi({
                id: eachItemData.id,
                itemId: receivingLotData[eachDataIndex]?.lotList?.length
                  ? eachItemData.itemData.id !==
                    eachItemData.itemData.existingItemId
                    ? eachItemData.itemData.id
                    : undefined
                  : eachItemData.itemData.id,
                unitPrice: eachItemData.unitPrice,
                quantity: eachItemData.quantity,
              }),
            );
          } else {
            editPlanningItemsRes.push(new Promise((resolve) => resolve(true)));
          }
        } else {
          editPlanningItemsRes.push(
            await postReceivingPlanningItemApi({
              receivingId: receivingId,
              itemId: eachItemData.itemData.id,
              unitPrice: eachItemData.unitPrice,
              quantity: eachItemData.quantity,
            }).then((res) => {
              if (res.id && receivingLotData[eachDataIndex]?.lotList?.length) {
                return Promise.all(
                  receivingLotData[eachDataIndex].lotList.map((eachLotData) => {
                    return postIncominfgPlanItemLotApi({
                      type: 'receiving',
                      planId: receivingId,
                      planItemId: res.id,
                      itemId: receivingLotData[eachDataIndex].itemInfo.id,
                      quantity: eachLotData.quantity,
                      lotNumber: eachLotData.lotNumber,
                    });
                  }),
                );
              }
              return res;
            }),
          );
        }
      }

      return Promise.all(editPlanningItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.length) {
              eachRes.forEach((nestedEachRes) => {
                if (nestedEachRes.code) throw nestedEachRes;
              });
            } else if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteReceivingPlanningItemById = async (deleteBomItemList) => {
    try {
      const deleteReceivingPlanningItemRes = deleteBomItemList.map(
        (eachDeleteBomItemId) => {
          return deleteReceivingPlanningItemApi(eachDeleteBomItemId);
        },
      );
      return Promise.all(deleteReceivingPlanningItemRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateReceivingPlanningInfo = async (modifyReceivingInfo) => {
    try {
      const res = await patchReceivingPlanningApi(modifyReceivingInfo);
      if (res.code) throw res;

      if (modifyReceivingInfo.deleteLotList.length) {
        const deleteBomRes = await this.deleteLotListById(
          modifyReceivingInfo.deleteLotList,
          'receiving',
        );
        if (deleteBomRes !== true) throw deleteBomRes;
      }

      if (modifyReceivingInfo.receivingItemData.length) {
        const editItemRes = await this.editReceivingPlanningItems(
          modifyReceivingInfo.id,
          modifyReceivingInfo.receivingItemData,
          modifyReceivingInfo.receivingLotData,
        );
        if (editItemRes !== true) throw editItemRes;
      }

      if (modifyReceivingInfo.deleteItemList.length) {
        const dleteBomItemRes = await this.deleteReceivingPlanningItemById(
          modifyReceivingInfo.deleteItemList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      if (modifyReceivingInfo.receivingLotData.length) {
        const editLotRes = await this.editIncominfgPlanItemLot({
          receivingLotData: modifyReceivingInfo.receivingLotData,
          planId: modifyReceivingInfo.id,
        });
        if (editLotRes !== true) throw editLotRes;
      }

      if (modifyReceivingInfo.isComplete) {
        const completeRes = await completeReceivingPlanningApi({
          ...modifyReceivingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (completeRes !== 'OK') throw completeRes;
      } else if (modifyReceivingInfo.isCanceled) {
        const cancelRes = await cancelReceivingPlanningApi({
          ...modifyReceivingInfo,
          timeZone: this.utilStore.getTimezone(),
        });
        if (cancelRes !== 'OK') throw cancelRes;
      }

      this.alertStore.setAlertOpen(
        'success',
        '입고_입고 계획 수정에 성공하였습니다',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteReceivingPlanningById = async (selectedReceivingList) => {
    try {
      const deleteItemTasks = selectedReceivingList.map((itemInfo) => {
        return deleteReceivingPlanningApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllReceivingPlanning();
          this.alertStore.setAlertOpen(
            'success',
            '입고_입고 계획 삭제를 완료했습니다',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getReceivingHistoryInfo = async (getReceivingInfo) => {
    try {
      const res = await getReceivingHistoryInfoApi(getReceivingInfo);
      if (res.code) throw res;
      this.receivingHistoryInfo = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllReceivingHistory = async (getReceivingHistory) => {
    try {
      const res = await getAllReceivingHistoryApi({
        ...getReceivingHistory,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.receivingList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteReceivingHistoryById = async (selectedReceivingList) => {
    try {
      const deleteItemTasks = selectedReceivingList.map((itemInfo) => {
        return deleteReceivingHistoryApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllReceivingHistory();
          this.alertStore.setAlertOpen(
            'success',
            '입고_입고 이력 삭제를 완료했습니다',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewItem = async (newItemInfo) => {
    try {
      const newItemRes = await postItemApi(newItemInfo);
      if (newItemRes.code) throw newItemRes;

      const newItemId = newItemRes.data?.id;

      if (newItemInfo.newBomList.length) {
        const newBomRes = await this.createNewBomList(
          newItemInfo.newBomList.map((eachNewBomData) => {
            return {
              ...eachNewBomData,
              itemId: newItemRes.data.id,
            };
          }),
        );
        if (newBomRes !== true) throw newBomRes;
      }

      if (newItemId) {
        const defaultItemDetail = newItemInfo.itemDetail.filter(
          (eachItemDetail) => eachItemDetail.isDefaultKey,
        );

        const sortedItemDetailData = [
          ...newItemRes.data.defaultDetailData,
        ].sort(
          (prevData, nextData) =>
            prevData.defaultDetailId - nextData.defaultDetailId,
        );

        const setAddedDetailId = [];

        defaultItemDetail.forEach((eachItemDetailData) => {
          if (eachItemDetailData.value) {
            const test = sortedItemDetailData.find(
              (eachSortedDetailData) =>
                eachSortedDetailData.key === eachItemDetailData.key,
            );
            setAddedDetailId.push({ ...eachItemDetailData, id: test.detailId });
          }
        });

        if (setAddedDetailId.length) {
          const editDefaultItemDetailRes = await patchMesItemDetailApi({
            itemId: newItemId,
            detailData: setAddedDetailId,
          });
          if (editDefaultItemDetailRes !== true) throw editDefaultItemDetailRes;
        }

        const extraItemDetail = newItemInfo.itemDetail.filter(
          (eachItemDetail) => !eachItemDetail.isDefaultKey,
        );

        if (extraItemDetail.length) {
          const newItemDetailRes = await postMesItemDetailApi({
            itemId: newItemId,
            detailData: extraItemDetail,
          });
          if (newItemDetailRes !== true) throw newItemDetailRes;
        }
      }

      this.alertStore.setAlertOpen(
        'success',
        '품목_품목 추가에 성공하였습니다',
      );
      return newItemId;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllItems = async () => {
    try {
      const res = await getAllItemsApi();
      if (res.code) throw res;
      this.items = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemInfo = async (getItemInfo) => {
    try {
      // 숫자를 세 자리마다 쉼표로 구분하는 함수
      const addCommas = (num) => {
        const parts = num.toString().split('.');
        if (parts?.[0]) {
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return parts.join('.');
      };

      const res = await getItemInfoApi(getItemInfo);
      if (res.code) throw res;

      this.itemInfo = {
        ...res.data,
        cost: addCommas(parseFloat(res.data.cost)),
        sellPrice: addCommas(parseFloat(res.data.sellPrice)),
      };
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteItemImageById = async (selectedItemImages) => {
    try {
      const deleteItemImages = selectedItemImages.map((imageInfo) => {
        return deleteImageApi(imageInfo.id);
      });
      return Promise.all(deleteItemImages)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteBomListById = async (deleteBomList) => {
    try {
      const deleteBomRes = deleteBomList.map((eachDeleteBomId) => {
        return deleteBomListApi(eachDeleteBomId);
      });
      return Promise.all(deleteBomRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  createNewBomList = async (newBomList) => {
    try {
      const createNewBomRes = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (let eachBomInfo of newBomList) {
        await postBomInfoApi(eachBomInfo)
          .then((res) => {
            if (res.code) createNewBomRes.push(res);
            else createNewBomRes.push(true);
          })
          .catch((error) => {
            this.alertStore.setAlertOpen('error', error);
            createNewBomRes.push(error);
          });
      }

      return Promise.all(createNewBomRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) {
              throw eachRes;
            }
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editBomListAndAddItems = async (editBomList) => {
    try {
      const editBomDefaultInfoRes = editBomList.map((eachBomInfo) => {
        return patchBomInfoApi({
          id: eachBomInfo.id,
          name: eachBomInfo.name,
          totalPrice: eachBomInfo.totalPrice,
          description: eachBomInfo.description,
        });
      });

      const addOrEditBomItemsRes = editBomList.map((eachBomInfo) => {
        return eachBomInfo.bomItemData.map((eachBomItem) => {
          if (eachBomItem.idInBomItemData) {
            return patchBomItemApi({
              id: eachBomItem.idInBomItemData,
              itemId: eachBomItem.itemId,
              cost: eachBomItem.cost,
              quantity: eachBomItem.quantity,
              description: eachBomItem.description,
            });
          }
          return postBomItemApi({
            itemId: eachBomItem.itemId,
            bomId: eachBomInfo.id,
            quantity: eachBomItem.quantity,
            description: eachBomItem.description,
            cost: eachBomItem.cost,
          });
        });
      });

      return Promise.all(editBomDefaultInfoRes, addOrEditBomItemsRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteBomItemById = async (deleteBomItemList) => {
    try {
      const deleteBomItemRes = deleteBomItemList.map((eachDeleteBomItemId) => {
        return deleteBomItemApi(eachDeleteBomItemId);
      });
      return Promise.all(deleteBomItemRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateItemDetail = async (itemId, itemDetailList) => {
    try {
      const initNewItemDetailList = [];
      const initEditItemDetailList = [];

      const newItemDetailRes = [];
      const editItemDetailRes = [];
      const deleteItemDetailRes = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const [
        eachItemIndex,
        eachItemDetail,
      ] of itemDetailList.entries()) {
        if (!eachItemDetail.id) {
          initNewItemDetailList.push(eachItemDetail);
        } else if (eachItemDetail.deleting) {
          deleteItemDetailRes.push(
            await deleteMesItemDetailApi(itemId, eachItemDetail.id),
          );
        } else if (eachItemDetail.editing && !eachItemDetail.deleting) {
          initEditItemDetailList.push(eachItemDetail);
        }

        if (eachItemIndex === itemDetailList.length - 1) {
          if (initNewItemDetailList.length) {
            newItemDetailRes.push(
              await postMesItemDetailApi({
                itemId,
                detailData: initNewItemDetailList,
              }),
            );
          }

          if (initEditItemDetailList.length) {
            editItemDetailRes.push(
              await patchMesItemDetailApi({
                itemId,
                detailData: initEditItemDetailList,
              }),
            );
          }
        }
      }

      return Promise.all([
        ...newItemDetailRes,
        ...editItemDetailRes,
        ...deleteItemDetailRes,
      ])
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) {
              throw eachRes;
            }
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  updateItemInfo = async (modifyItemInfo) => {
    try {
      if (modifyItemInfo.isEditImage && modifyItemInfo.registeredImageId) {
        const imageDeleteRes = await this.deleteItemImageById([
          { id: modifyItemInfo.registeredImageId },
        ]);
        if (imageDeleteRes !== true) throw imageDeleteRes;
      }

      if (modifyItemInfo.deleteBomList.length) {
        const deleteBomRes = await this.deleteBomListById(
          modifyItemInfo.deleteBomList,
        );
        if (deleteBomRes !== true) throw deleteBomRes;
      }

      if (modifyItemInfo.newBomList.length) {
        const newBomRes = await this.createNewBomList(
          modifyItemInfo.newBomList,
        );
        if (newBomRes !== true) throw newBomRes;
      }

      if (modifyItemInfo.editBomList.length) {
        const editBomRes = await this.editBomListAndAddItems(
          modifyItemInfo.editBomList,
        );
        if (editBomRes !== true) throw editBomRes;
      }

      if (modifyItemInfo.deleteBomItemList.length) {
        const dleteBomItemRes = await this.deleteBomItemById(
          modifyItemInfo.deleteBomItemList,
        );
        if (dleteBomItemRes !== true) throw dleteBomItemRes;
      }

      if (modifyItemInfo.itemDetail.length) {
        const updateItemDetailRes = await this.updateItemDetail(
          modifyItemInfo.id,
          modifyItemInfo.itemDetail,
        );

        if (updateItemDetailRes !== true) throw updateItemDetailRes;
      }

      const res = await patchItemApi(modifyItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목_품목 수정에 성공하였습니다',
      );
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteItemsById = async (selecteditmes) => {
    try {
      const deleteItemTasks = selecteditmes.map((itemInfo) => {
        return deleteItemApi(itemInfo.id);
      });
      return Promise.all(deleteItemTasks)
        .then(() => {
          this.getAllItems();
          this.alertStore.setAlertOpen(
            'success',
            '품목_품목 삭제를 완료했습니다',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewItemType = async (newItemInfo) => {
    try {
      const res = await postItemTypeApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 종류 추가에 성공하였습니다',
      );
      this.getItemType();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemType = async () => {
    try {
      const res = await getItemTypeApi();
      if (res.code) throw res;
      this.itemType = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemType = async (modifyItemType) => {
    try {
      const res = await patchItemApi(modifyItemType);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemType = async (deleteItemTypeId) => {
    try {
      const res = await deleteItemTypeApi(deleteItemTypeId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 종류 삭제에 성공하였습니다',
      );
      this.getItemType();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createNewItemUnit = async (newItemInfo) => {
    try {
      const res = await postItemUnitApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 추가에 성공하였습니다',
      );
      this.getItemUnit();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemUnit = async () => {
    try {
      const res = await getItemUnitApi();
      if (res.code) throw res;
      this.itemUnit = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemUnit = async (getItemInfo) => {
    try {
      const res = await patchItemApi(getItemInfo);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemUnit = async (deleteItemUnitId) => {
    try {
      const res = await deleteItemUnitApi(deleteItemUnitId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 삭제에 성공하였습니다',
      );
      this.getItemUnit();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createNewItemCountry = async (newItemInfo) => {
    try {
      const res = await postItemCountryApi(newItemInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 추가에 성공하였습니다',
      );
      this.getItemCountry();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getItemCountry = async () => {
    try {
      const res = await getItemCountryApi();
      if (res.code) throw res;
      this.itemCountry = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  updateItemCountry = async (getItemInfo) => {
    try {
      const res = await patchItemApi(getItemInfo);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteItemCountry = async (deleteItemCountryId) => {
    try {
      const res = await deleteItemCountryApi(deleteItemCountryId);
      if (res.code) throw res;
      this.alertStore.setAlertOpen(
        'success',
        '품목 단위 삭제에 성공하였습니다',
      );
      this.getItemCountry();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getBomList = async (getBomList) => {
    try {
      const res = await getBomInfoApi(getBomList);
      if (res.code) throw res;
      this.bomList = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getBomInfo = async (getBomInfo) => {
    try {
      const res = await getBomInfoApi(getBomInfo);
      if (res.code) throw res;
      this.bomInfo = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getAllContacts = async () => {
    try {
      const res = await getContactApi();
      if (res.code) throw res;
      this.contacts = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editIncominfgPlanItemLot = async (addOrEditLotInfo) => {
    try {
      const addOrEditLotRes = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (let eachLotInfo of addOrEditLotInfo.receivingLotData) {
        // eslint-disable-next-line no-restricted-syntax
        for await (let eachInfoLotList of eachLotInfo.lotList) {
          if (eachInfoLotList.editing) {
            if (eachInfoLotList.id) {
              addOrEditLotRes.push(
                await patchIncominfgPlanItemLotApi({
                  type: 'receiving',
                  id: eachInfoLotList.id,
                  quantity: eachInfoLotList.quantity,
                  lotNumber: eachInfoLotList.lotNumber,
                }),
              );
            } else if (eachLotInfo.planItemId) {
              addOrEditLotRes.push(
                await postIncominfgPlanItemLotApi({
                  type: 'receiving',
                  planId: addOrEditLotInfo.planId,
                  planItemId: eachLotInfo.planItemId,
                  itemId: eachLotInfo.itemInfo.id,
                  quantity: eachInfoLotList.quantity,
                  lotNumber: eachInfoLotList.lotNumber,
                }),
              );
            }
          } else {
            addOrEditLotRes.push(new Promise((resolve) => resolve(true)));
          }
        }
      }
      return Promise.all(addOrEditLotRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  editOutgoingPlanItemLot = async (editLotInfo) => {
    try {
      const addOrEditLotRes = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (let eachLotInfo of editLotInfo.shippingLotData) {
        // eslint-disable-next-line no-restricted-syntax
        for await (let eachInfoLotList of eachLotInfo.lotList) {
          if (eachInfoLotList.editing) {
            if (eachInfoLotList.id) {
              addOrEditLotRes.push(
                await patchOutgoingPlanItemLotApi({
                  type: 'shipping',
                  id: eachInfoLotList.id,
                  quantity: eachInfoLotList.quantity,
                  lotId: eachInfoLotList.lotId,
                }),
              );
            } else if (eachLotInfo.planItemId) {
              addOrEditLotRes.push(
                await postOutgoingPlanItemLotApi({
                  type: 'shipping',
                  planId: editLotInfo.planId,
                  planItemId: eachLotInfo.planItemId,
                  itemId: eachLotInfo.itemInfo.id,
                  quantity: eachInfoLotList.quantity,
                  lotId: eachInfoLotList.lotId,
                }),
              );
            }
          } else {
            addOrEditLotRes.push(new Promise((resolve) => resolve(true)));
          }
        }
      }
      return Promise.all(addOrEditLotRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteLotListById = async (deleteLotList, type) => {
    try {
      const deleteLotRes = deleteLotList.map((eachDeleteLotId) => {
        return deleteIncominfgPlanItemLotApi(eachDeleteLotId, type);
      });
      return Promise.all(deleteLotRes)
        .then((resList) => {
          resList.forEach((eachRes) => {
            if (eachRes.code) throw eachRes;
          });
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  initLotList = () => {
    this.lotList = [];
  };

  getLotList = async (getLotList) => {
    try {
      const res = await getLotListApi(getLotList);
      if (res.code) throw res;
      this.lotList = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  initLotInfo = () => {
    this.lotInfo = {
      id: null,
      lotNumber: null,
      quantity: null,
      productionDate: null,
      createdAt: null,
      updatedAt: null,
      itemData: {
        id: null,
        name: null,
        code: null,
        unit: null,
        itemType: null,
      },
      assignedQuantity: null,
      assignableQuantity: null,
    };
  };

  getLotInfo = async (getLotInfo) => {
    try {
      const res = await getLotListApi(getLotInfo);
      if (res.code) throw res;
      this.lotInfo = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getOutgoingLotList = async (getOutoingLotList) => {
    try {
      const res = await getOutgoingPlanItemLotListApi(getOutoingLotList);
      if (res.code) throw res;
      this.outgoingLotList = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getStockList = async (getStockList) => {
    try {
      const res = await getStockListApi(getStockList);
      if (res.code) throw res;
      this.stockList = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createStockAdjustment = async (newStockAdjustmentInfo) => {
    try {
      const res = await postStockAdjustmentApi(newStockAdjustmentInfo);
      if (res.code) throw res;
      this.alertStore.setAlertOpen('success', '재고 조정에 성공하였습니다');
      this.getStockList();
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  initStockAdjustmentHistoryList = () => {
    this.stockAdjustmentHistoryList = [];
  };

  getStockAdjustmentHistoryList = async (getStockAdjustmentHistoryList) => {
    try {
      const res = await getStockAdjustmentHistoryApi({
        ...getStockAdjustmentHistoryList,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.stockAdjustmentHistoryList = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  initStockTraceList = () => {
    this.stockTraceList = [];
  };

  getStockTraceList = async (getStockTraceInfo) => {
    try {
      const res = await getStockTraceApi({
        ...getStockTraceInfo,
        timeZone: this.utilStore.getTimezone(),
      });
      if (res.code) throw res;
      this.stockTraceList = res.data;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  initMesItemDefaultDetailKey = () => {
    this.stockAdjustmentHistoryList = [];
  };

  getMesItemDefaultDetailKeyList = async () => {
    try {
      const res = await getDefaultDetailKeyApi();
      if (res.code) throw res;
      this.itemDetailDefaultKeyList = res.data;
      return res.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };
}

export default mesStore;
