import axios from 'src/utils/axios';

export const postSupportCategory = async (postInfo) => {
  try {
    const res = await axios.post(`/edmu/support/category`, {
      name: postInfo.name,
      loginRequired: postInfo.loginRequired,
      commentAble: postInfo.commentAble,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getSupportCategory = async (getInfo) => {
  try {
    const res = await axios.get(`/edmu/support/category`, {
      params: {
        'login-required': getInfo?.loginRequired || undefined,
        'comment-able': getInfo?.commentAble || undefined,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const patchSupportCategory = async (patchInfo) => {
  const res = await axios.patch(
    `edmu/support/category?id=${patchInfo.id}`,
    patchInfo.data,
  );
  return res;
};

export const deleteSupportCategory = async (deleteId) => {
  const res = await axios.delete(`edmu/support/category?id=${deleteId}`);
  return res;
};

export const postSupportContact = async (postInfo) => {
  try {
    const res = await axios.post(`/edmu/support/contact`, {
      categoryId: postInfo.categoryId,
      firstName: postInfo.firstName,
      lastName: postInfo.lastName,
      company: postInfo.company,
      email: postInfo.email,
      countryCode: postInfo.countryCode,
      phone: postInfo.phone,
      message: postInfo.message,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getSupportContact = async (getInfo) => {
  try {
    const res = await axios.get(`/edmu/support/contact`, {
      params: {
        id: getInfo?.id || undefined,
        state: getInfo?.state || undefined,
        'category-id': getInfo?.categoryId || undefined,
      },
    });
    return res.data;
  } catch (error) {
    return error;
  }
};
