import { Loader, retryLazy } from 'src/utils/lazyUtil';

const Pricing = Loader(retryLazy(() => import('src/pages/support/Pricing/')));

const ContactUs = Loader(
  retryLazy(() => import('src/pages/support/ContactUs/')),
);

const RequestList = Loader(
  retryLazy(() => import('src/pages/support/RequestList/')),
);

export const supportRoutes = [
  {
    path: 'contact',
    element: <ContactUs />,
  },
  {
    path: 'pricing',
    element: <Pricing />,
  },
];

export const signInSupportRoutes = [
  {
    path: 'list',
    element: <RequestList />,
  },
];
