import React from 'react';
import styled from 'styled-components';
import Header from 'src/components/Header';
import 'src/pages/information/assets/scss/style.scss';
import { InfoFooter, InfoMain } from '../style';

const InfomationContainer = styled.div``;

const LayoutDefault = ({ children }) => (
  <InfomationContainer id="information-container">
    <Header navPosition="right" className="reveal-from-bottom" />
    <InfoMain className="site-content ">{children}</InfoMain>
    <InfoFooter />
  </InfomationContainer>
);

export default LayoutDefault;
