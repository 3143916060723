import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postNewInspectionHistory = async (newInspectionInfo) => {
  try {
    const formData = new FormData();
    formData.append('deviceId', newInspectionInfo.deviceData.id);
    formData.append('workerId', newInspectionInfo.workerId);
    formData.append('title', newInspectionInfo.title);
    formData.append('timeZone', newInspectionInfo.timeZone);
    formData.append('maintenanceDate', newInspectionInfo.maintenanceDate);

    if (newInspectionInfo.description)
      formData.append('description', newInspectionInfo.description);
    if (newInspectionInfo.checkItems.length) {
      newInspectionInfo.checkItems.forEach((eachCheckItemData, itemIndex) => {
        formData.append(
          `checkItems[${itemIndex}][checklistItem]`,
          eachCheckItemData.checklistItem,
        );
        formData.append(
          `checkItems[${itemIndex}][checkContent]`,
          eachCheckItemData.checkContent,
        );
        formData.append(
          `checkItems[${itemIndex}][checkResult]`,
          eachCheckItemData.checkResult,
        );
        if (eachCheckItemData.imageKey.length) {
          eachCheckItemData.imageKey.forEach((eachImageKey, keyIndex) => {
            formData.append(
              `checkItems[${itemIndex}][imageKey][${keyIndex}]`,
              eachImageKey,
            );
          });
        }
      });
    }
    if (newInspectionInfo.regularMaintenanceImage.length) {
      newInspectionInfo.regularMaintenanceImage.forEach((eachImageData) => {
        formData.append(
          'regularMaintenanceImage',
          imageToBlob(eachImageData.image),
          eachImageData.name,
        );
      });
    }
    const res = await axios.post(
      'edmu/device/regular-maintenance-history',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllInspectionHistories = async (requestInfo) => {
  const res = await axios.get('edmu/device/regular-maintenance-history', {
    params: {
      'device-id': requestInfo?.deviceId || null,
      start: requestInfo?.start || null,
      end: requestInfo?.end || null,
      'time-zone': requestInfo?.timeZone || null,
      'sort-type': requestInfo?.sortType || null,
      limit: requestInfo?.limit || null,
    },
  });
  return res.data;
};

export const getInspectionHistoryById = async (requestInfo) => {
  const res = await axios.get('edmu/device/regular-maintenance-history', {
    params: {
      id: requestInfo?.historyId,
      'device-id': requestInfo?.deviceId || null,
      start: requestInfo?.start || null,
      end: requestInfo?.end || null,
      'time-zone': requestInfo?.timeZone || null,
      'sort-type': requestInfo?.sortType || null,
      limit: requestInfo?.limit || null,
    },
  });
  return res.data;
};

export const patchInspectionHistory = async (modifyInfo) => {
  const res = await axios.patch(
    `edmu/device/regular-maintenance-history?id=${modifyInfo.id}`,
    { ...modifyInfo.data, timeZone: modifyInfo.timeZone },
  );
  return res;
};

export const deleteInspectionHistory = async (historyId) => {
  const res = await axios.delete(
    `edmu/device/regular-maintenance-history?id=${historyId}`,
  );
  return res;
};

export const postInspectionImage = async (dataInfo) => {
  const formData = new FormData();

  if (dataInfo.images?.length) {
    dataInfo.images.forEach((eachImageData) => {
      formData.append('includeKey', true);
      formData.append(
        'image',
        imageToBlob(eachImageData.image),
        eachImageData.name,
      );
    });
  }

  const res = await axios.post(
    `edmu/image/regular-maintenance-history?type-id=${dataInfo.historyId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res;
};

export const deleteInspectionImage = async (imageId) => {
  const res = await axios.delete(`edmu/image?id=${imageId}`);
  return res;
};
