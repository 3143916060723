import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/mobx-store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Card,
  DialogTitle,
  Grid,
  IconButton,
  Box,
} from '@mui/material';
import AlertIconList from 'src/assets/icons/AlertIconList';
import CloseIcon from '@mui/icons-material/Close';
import { AlertDialog } from './style';

const ModalAlert = observer(({ message }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { alertStore } = useStore();
  const { type, linkTo, setAlertClose } = alertStore;

  const handleModalAlertClose = () => {
    setAlertClose();
    if (linkTo) navigate(linkTo);
  };

  return (
    <AlertDialog open fullWidth minWidth="lg" type={type}>
      <DialogTitle>
        <Grid container>
          <Card
            component={'span'}
            className="alert_type_icon"
            variant="outlined"
          >
            {AlertIconList[type]}
          </Card>
          <Typography variant="h4" gutterBottom className="dialog_title_text">
            {t('알림')}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleModalAlertClose}
            className="close_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Box mb={1} mt={1} display="flex">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h4" className="alert_message">
                  {message}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleModalAlertClose}>
          {t('확인')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
});

export default ModalAlert;
