import { makeAutoObservable, runInAction } from 'mobx';
import {
  postNewProduct,
  getProductList,
  patchProductInfo,
  deleteProduct,
} from 'src/api/product';

class productStore {
  alertStore;

  products = [];

  storedManufacturerAddress = [];

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  // eslint-disable-next-line class-methods-use-this
  createProduct = async (newProductInfo) => {
    try {
      const productInfoRes = await postNewProduct(newProductInfo).then(
        (res) => {
          if (res.response) {
            throw res;
          }
          this.alertStore.setAlertOpen('success', '제품 추가에 성공하였습니다');
          return res;
        },
      );
      return productInfoRes.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllProductList = async () => {
    try {
      const res = await getProductList();

      this.products = res;

      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getManufacturerInfoById = async (drawingId) => {
    try {
      const res = await getProductList(drawingId);
      runInAction(() => {
        this.drawingInfo = res;
      });
      return true;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateProduct = async (modifyInfo) => {
    try {
      const res = await patchProductInfo(modifyInfo);
      if (res.code) {
        throw res;
      }
      this.alertStore.setAlertOpen('success', '제품 수정을 완료하였습니다');
      this.getAllProductList();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteProductById = async (selectedManufacturers) => {
    try {
      const deleteManufacturerTasks = selectedManufacturers.map(
        (manufacturerInfo) => {
          return deleteProduct(manufacturerInfo.id);
        },
      );
      return Promise.all(deleteManufacturerTasks)
        .then(() => {
          this.getAllProductList();
          this.alertStore.setAlertOpen('success', `제품 삭제를 완료했습니다`);
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };
}

export default productStore;
