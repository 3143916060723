import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from './elements/Image';
import { useTheme } from '@mui/material';
import VersionChip from './elements/VersionChip';
import { useStore } from 'src/mobx-store';

const Logo = ({
  className,
  displayVersion = true,
  forceColor = null,
  ...props
}) => {
  const { accountStore } = useStore();
  const { account } = accountStore;
  const [temporaryImage, setTemporaryImage] = useState(false);

  const theme = useTheme();
  const classes = classNames('brand', className);

  let logoColor;

  if (forceColor) {
    logoColor = forceColor;
  } else {
    logoColor = theme.palette.mode;
  }

  useEffect(() => {
    if (
      account.code === 'U2280000445' ||
      account.code === 'U9820000446' ||
      account.code === 'U7550000447'
    ) {
      setTemporaryImage(true);
    } else {
      setTemporaryImage(false);
    }
  }, [account.code]);

  useEffect(() => {}, [temporaryImage]);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to={props.linkUrl ? `/${props.linkUrl}` : '/'}>
          {displayVersion && (
            <VersionChip label={`V.${process.env.REACT_APP_VERSION}`} />
          )}
          {logoColor === 'light' ? (
            temporaryImage ? (
              <Image
                // eslint-disable-next-line global-require
                src={require('src/pages/information/assets/images/temporary.png')}
                alt="Open"
                width={150}
              />
            ) : (
              <Image
                // eslint-disable-next-line global-require
                src={require('src/pages/information/assets/images/okomoForLightNew.png')}
                alt="Open"
                width={110}
              />
            )
          ) : temporaryImage ? (
            <Image
              // eslint-disable-next-line global-require
              src={require('src/pages/information/assets/images/temporary.png')}
              alt="Open"
              width={150}
            />
          ) : (
            <Image
              // eslint-disable-next-line global-require
              src={require('src/pages/information/assets/images/okomoForDarkNew.png')}
              alt="Open"
              width={110}
            />
          )}
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
