import { makeAutoObservable } from 'mobx';
import {
  getSupportCategory,
  getSupportContact,
  postSupportContact,
} from 'src/api/support';

class supportStore {
  alertStore;

  supportCategory = [];

  requestList = [];

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  getAllSupportCategoryList = async () => {
    try {
      const res = await getSupportCategory();
      this.supportCategory = res;
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  createNewSupportContact = async (newContactSupport) => {
    try {
      const id = await postSupportContact(newContactSupport).then((res) => {
        this.alertStore.setAlertOpen(
          'success',
          '문의_문의 주셔서 감사합니다. 빠른 시일 내에 답변드리겠습니다.',
          { mode: 'modal' },
        );
        if (res.code) throw res;
        return res.data.id;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllRequestList = async () => {
    try {
      const res = await getSupportContact();
      if (res.code) throw res;

      this.requestList = res;
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      this.requestList = [];
      return false;
    }
  };
}

export default supportStore;
