const koJSON = {
  /**
   * 사용 범위 : Helmet
   * 업데이트 : 23.07.19
   * 작성자 : 서연주
   */
  info: '정보',
  join: '가입',
  create: '생성',
  user: '사용자',
  'create admin': '관리자 생성',
  디테일탭: {
    '': '',
    main: '메인',
    status: '설비상태',
    control: '현장제어',
    'realtime-monitoring': '실시간 모니터링',
    measurement: '계측',
    ai: '현장설비예측',
    log: '설비로그',
    setting: '통신장비설정',
  },
  /**
   * 사용 범위 : 장비 리스트 목록 Key변환
   * 업데이트 : 23.05.04
   * 작성자 : 서연주
   */
  // SidebarMenu
  sensors: '계측',
  managements: '상태',
  control: '제어',
  all: '모두',
  'one-phase-solar': '태양광 단상',
  'three-phase-solar': '태양광 삼상',
  fs600r: 'FS-600R',
  router: 'EDMU',
  'temperature-controller': '온도 제어기',
  thermohygrometer: '온습도계',
  'solar-thermal': '태양열',
  'solar-water-heater': '태양열 자연 순환식 온수기',
  geothermal: '지열',
  wind: '풍력',
  'fuel-cell': '연료전지',
  ess: '에너지 저장 시스템',
  'electric-vehicle-charger': '전기차 충전기',
  default: '기본 설비',

  // LogType
  deviceConnection: '장비 연결',
  deviceConnectionTimeout: '연결 시간 초과',
  deviceConnectionError: '연결 오류',
  deviceConnectionUnknown: '알 수 없는 연결',
  deviceControlApply: '제어 적용',
  deviceControlTimeout: '제어 시간 초과',
  deviceControl: '장비 제어',
  deviceBroken: '장비 고장',
  deviceBrokenRestore: '장비 복구',
  routerInvalidTimestamp: '잘못된 시간 형식',
  routerInvalidJson: '잘못된 JSON 형식',
  routerDataError: 'EDMU 데이터 에러',
  /**
   * 사용 범위 : 사이드바 레이아웃(사이드바, 헤더)
   * 업데이트 : 23.07.07
   * 작성자 : 서연주
   */
  // SidebarLayout
  '업그레이드 메세지': '평가판이 {{day}}일 이후에 종료됩니다',

  // Ag Grid Device Info Column
  id: '장비 ID',
  type: '종류',
  nickname: '장비 이름',
  isConnected: '연결',
  modelName: '모델 이름',
  serial: '장비 시리얼',
  firmwareVersion: '펌웨어 버전',
  'model.name': '모델명',
  'location.name': '장비 위치 이름',
  createdAt: '설치 일자',
  routerSerial: '라우터 시리얼',
  function: '기능',
  link: '상세보기',
  lastConnectionTime: '마지막 통신시간',
  operationState: '동작 상태',
  /**
   * 사용 범위 : DeviceDetail 내부 컴포넌트
   * 업데이트 : 23.03.03
   * 작성자 : 서연주
   */

  // LogTable
  notice: '알림',
  confirm: '확인',
  warning: '주의',
  alert: '경보',

  /**
   * 사용 범위 : 그룹설정과 관련된 내부 컴포넌트
   * 업데이트 : 23.03.29
   * 작성자 : 정태원
   */
  '데이터 카드': {
    '월 ': '전월 대비',
    '일 ': '{{dayValue}}일 ',
    '시간 ': '{{hourValue}}시간 ',
    '분 ': '{{minValue}}분 ',
    '초 ': '{{secValue}}초 ',
    '시간 에러': '시간 에러',
    '전 대비': '전 대비',
  },
  '에러코드 처리가 필요합니다.':
    '에러코드 처리가 필요합니다. [CODE : {{errorCode}}]',
  그룹: {
    그룹: '그룹',
    관리자: '관리자',
    사용자: '사용자',
    id: '사용자 ID',
    username: '이름',
    phone: '연락처',
    email: '이메일',
    role: '권한',
    admin: '관리자',
    user: '사용자',
    function: '기능',
  },
  제조업체: {
    id: 'ID',
    name: '제조업체 이름',
    businessNumber: '사업자 등록번호',
    contactNumber: '본사 연락처',
    'location.name': '본사 위치 이름',
    exContactNumber: '공장 연락처',
    'exLocation.name': '공장 위치',
    description: '비고',
    삭제: '삭제',
    수정: '수정',
    제조업체: '제조업체',
  },
  '교체/수리': {
    id: 'ID',
    title: '제목',
    'deviceData.nickname': '장비 이름',
    reporterName: '작성자 이름',
    reporterPhone: '작성자 연락처',
    reporterEmail: '작성자 이메일',
    workerName: '작업자 이름',
    workerPhone: '작업자 연락처',
    workerEmail: '작업자 이메일',
    serviceType: '서비스 종류',
    reportDate: '접수 일자',
    dispatchDate: '출동 일자',
    maintenanceDate: '점검 일자',
    issueDescription: '문제 현상',
    actionTaken: '조치 내용',
    description: '비고',
    삭제: '삭제',
    bs: '사전 서비스',
    as: '사후 서비스',
    images: '현장 사진',
    reporterId: '작성자',
    workerId: '작업자',
    'reporter.name': '작성자',
    'worker.name': '작업자',
    status: '상태',
    pending: '대기',
    'in progress': '진행중',
    completed: '완료',
    failed: '실패',
    수정: '수정',
  },
  정기점검: {
    id: 'ID',
    title: '제목',
    'deviceData.nickname': '장비 이름',
    reporterName: '작성자 이름',
    reporterPhone: '작성자 연락처',
    reporterEmail: '작성자 이메일',
    workerName: '작업자 이름',
    workerPhone: '작업자 연락처',
    workerEmail: '작업자 이메일',
    serviceType: '서비스 종류',
    maintenanceDate: '점검일자',
    description: '비고',
    삭제: '삭제',
    images: '현장 사진',
    checklist: '점검 목록',
    checklistItem: '점검 항목',
    checkContent: '점검 내용',
    checkResult: '결과',
    imageKey: '점검 사진',
    normal: '적합',
    warning: '주의',
    error: '부적합',
    reporterId: '작성자',
    'reporter.name': '작성자',
    workerId: '작업자',
    'worker.name': '작업자',
    status: '상태',
    pending: '대기',
    'in progress': '진행중',
    completed: '완료',
    failed: '실패',
    수정: '수정',
  },
  제품모델: {
    id: 'ID',
    name: '제품명',
    'manufacturingCompany.name': '제조업체 이름',
    'deviceType.name': '장비 종류',
    model: '모델명',
    description: '비고',
    삭제: '삭제',
    newManufacturingCompany: '새로운 제조업체 추가',
    수정: '수정',
  },
  주문: {
    '주문 내용 상세보기': '주문 내용 상세보기',
    '주문 이력 상세보기': '주문 이력 상세보기',
    '주문 등록': '주문 등록',
    '주문 정보': '주문 정보',
    '품목 정보': '품목 정보',
    '품목 코드': '품목 코드',
    '고객 정보': '고객 정보',
    '고객 이름': '고객 이름',
    '고객 유형': '고객 유형',
    id: 'ID',
    'itemData.name': '품명',
    code: '작업 코드',
    quantity: '주문 수량',
    unitPrice: '가격',
    eachItemTotalPrice: '가격 합계',
    shippingItemData: '주문 품목 정보',
    'workerData.name': '등록자',
    'contactData.name': '고객명',
    totalPrice: '총 가격',
    startDate: '등록 일시',
    dueDate: '완료 예정일',
    completeDate: '처리일',
    planState: '주문 상태',
    lot: 'LOT 관리',
    품목: '품목',
    가격: '가격',
    '총 가격': '총 가격',
    수량: '수량',
    waiting: '대기',
    'in progress': '진행중',
    complete: '완료',
    canceled: '취소',
    planning: '계획',
    history: '완료',
    단위: '단위',
    '납기 준수 여부': '납기 준수 여부',
    '주문 삭제': '주문 삭제',
    '주문 이력 삭제': '주문 이력 삭제',
    '선택된 주문을 삭제하겠습니까?': '선택된 주문을 삭제하겠습니까?',
    '선택된 주문 이력을 삭제하겠습니까?': '선택된 주문 이력을 삭제하겠습니까?',
    '품목 및 주문 수량을 입력해주세요': '품목 및 주문 수량을 입력해주세요',
    '주문 수량과 동일해야 합니다': '주문 수량과 동일해야 합니다',
    '주문 계획 등록에 성공하였습니다': '주문 계획 등록에 성공하였습니다',
    '주문 계획 수정에 성공하였습니다': '주문 계획 수정에 성공하였습니다',
    '주문 계획 삭제를 완료했습니다': '주문 계획 삭제를 완료했습니다',
    '주문 이력 삭제를 완료했습니다': '주문 이력 삭제를 완료했습니다',
  },
  생산: {
    생산: '생산',
    '생산 계획 등록': '생산 계획 등록',
    '생산 계획 상세보기': '생산 계획 상세보기',
    '생산 이력 상세보기': '생산 이력 상세보기',
    '생산 정보': '생산 정보',
    id: 'ID',
    code: '작업 코드',
    lotNumber: 'LOT 번호',
    'workerData.name': '등록자',
    'contactData.name': '고객명',
    'itemData.name': '생산 품목 정보',
    totalPrice: '총 가격',
    startDate: '등록 일시',
    dueDate: '완료 예정일',
    completeDate: '처리일',
    planState: '생산 상태',
    createdAt: '등록 일시',
    품목: '품목',
    cost: '원가',
    eachItemTotalCost: '가격 합계',
    quantity: '생산 수량',
    description: '비고',
    원가: '원가',
    가격: '가격',
    '총 가격': '총 가격',
    수량: '수량',
    '생산 수량': '생산 수량',
    비고: '비고',
    waiting: '대기',
    'in progress': '진행중',
    complete: '완료',
    canceled: '취소',
    planning: '계획',
    history: '완료',
    '품목 정보': '품목 정보',
    'BOM 정보': 'BOM 정보',
    'BOM 목록': 'BOM 목록',
    'BOM 제목': 'BOM 제목',
    'BOM 비고': 'BOM 비고',
    '생산 계획 삭제': '생산 계획 삭제',
    '생산 이력 삭제': '생산 이력 삭제',
    '선택된 생산 계획을 삭제하겠습니까?': '선택된 생산 계획을 삭제하겠습니까?',
    '선택된 생산 이력을 삭제하겠습니까?': '선택된 생산 이력을 삭제하겠습니까?',
    '품목 및 생산 수량을 입력해주세요': '품목 및 생산 수량을 입력해주세요',
    '생산 수량과 동일해야 합니다': '생산 수량과 동일해야 합니다',
    '생산 계획 등록에 성공하였습니다': '생산 계획 등록에 성공하였습니다',
    '생산 계획 수정에 성공하였습니다': '생산 계획 수정에 성공하였습니다',
    '생산 계획 삭제를 완료했습니다': '생산 계획 삭제를 완료했습니다',
    '생산 이력 삭제를 완료했습니다': '생산 이력 삭제를 완료했습니다',
    '품목 및 사용 수량을 입력해주세요': '품목 및 사용 수량을 입력해주세요',
    '사용 수량과 동일해야 합니다': '사용 수량과 동일해야 합니다',
  },
  입고: {
    '입고 계획 내용 상세보기': '입고 계획 내용 상세보기',
    '입고 이력 상세보기': '입고 이력 상세보기',
    '입고 계획 등록': '입고 계획 등록',
    '입고 정보': '입고 정보',
    '품목 정보': '품목 정보',
    '품목 코드': '품목 코드',
    id: 'ID',
    'itemData.name': '품명',
    code: '작업 코드',
    quantity: '입고 수량',
    unitPrice: '가격',
    'Lot 번호': 'Lot 번호',
    eachItemTotalPrice: '가격 합계',
    receivingItemData: '입고 품목 정보',
    'workerData.name': '등록자',
    'contactData.name': '공급처 이름',
    totalPrice: '총 가격',
    startDate: '등록 일시',
    dueDate: '완료 예정일',
    completeDate: '처리일',
    planState: '입고 상태',
    품목: '품목',
    가격: '가격',
    '가격 합계': '가격 합계',
    '총 가격': '총 가격',
    수량: '수량',
    '입고 수량': '입고 수량',
    'LOT 수량': 'LOT 수량',
    waiting: '대기',
    'in progress': '진행중',
    complete: '완료',
    canceled: '취소',
    planning: '계획',
    history: '완료',
    '입고 계획 삭제': '입고 계획 삭제',
    '입고 이력 삭제': '입고 이력 삭제',
    '선택된 입고 계획을 삭제하겠습니까?': '선택된 입고 계획을 삭제하겠습니까?',
    '선택된 입고 이력을 삭제하겠습니까?': '선택된 입고 이력을 삭제하겠습니까?',
    '공급처 정보': '공급처 정보',
    '공급처 이름': '공급처 이름',
    '공급처 유형': '공급처 유형',
    '품목 및 입고 수량을 입력해주세요': '품목 및 입고 수량을 입력해주세요',
    '입고 수량과 동일해야 합니다': '입고 수량과 동일해야 합니다',
    '입고 계획 등록에 성공하였습니다': '입고 계획 등록에 성공하였습니다',
    '입고 계획 수정에 성공하였습니다': '입고 계획 수정에 성공하였습니다',
    '입고 계획 삭제를 완료했습니다': '입고 계획 삭제를 완료했습니다',
    '입고 이력 삭제를 완료했습니다': '입고 이력 삭제를 완료했습니다',
  },
  출고: {
    id: 'ID',
    'itemData.name': '품명',
    '출고 이력 상세보기': '출고 이력 상세보기',
    '출고 정보': '출고 정보',
    '품목 정보': '품목 정보',
    '품목 코드': '품목 코드',
    code: '작업 코드',
    quantity: '출고 수량',
    unitPrice: '가격',
    eachItemTotalPrice: '가격 합계',
    shippingItemData: '출고 품목 정보',
    'workerData.name': '담당자',
    'contactData.name': '고객명',
    totalPrice: '총 가격',
    startDate: '등록 일시',
    dueDate: '완료 예정일',
    completeDate: '처리일',
    planState: '출고 상태',
    품목: '품목',
    가격: '가격',
    '총 가격': '총 가격',
    수량: '수량',
    waiting: '대기',
    'in progress': '진행중',
    complete: '완료',
    canceled: '취소',
    planning: '계획',
    history: '완료',
    단위: '단위',
    '출고 이력 삭제': '출고 이력 삭제',
    '선택된 출고 이력을 삭제하겠습니까?': '선택된 출고 이력을 삭제하겠습니까?',
    '고객 정보': '고객 정보',
    '고객 이름': '고객 이름',
    '고객 유형': '고객 유형',
    '납기 준수 여부': '납기 준수 여부',
    '출고 이력 삭제를 완료했습니다': '출고 이력 삭제를 완료했습니다',
  },
  품목: {
    제품: '제품',
    id: '품목 코드',
    code: '품목 코드',
    name: '품명',
    model: '품목 코드',
    'itemData.name': '품명',
    itemType: '품목 종류',
    key: '항목',
    value: '내용',
    '주문 품목 정보': '주문 품목 정보',
    '입고 품목 정보': '입고 품목 정보',
    '출고 품목 정보': '출고 품목 정보',
    '주문 수량': '주문 수량',
    '입고 수량': '입고 수량',
    '출고 수량': '출고 수량',
    '품목 등록': '품목 등록',
    '품목 정보': '품목 정보',
    품명: '품명',
    '품목 코드': '품목 코드',
    '품목 종류': '품목 종류',
    '품목 단위': '품목 단위',
    '생산 및 판매 정보': '생산 및 판매 정보',
    원가: '원가',
    원산지: '원산지',
    판매가: '판매가',
    '품목 상세보기': '품목 상세보기',
    '속성 정보': '속성 정보',
    '속성 삭제': '속성 삭제',
    '선택된 속성을 삭제하겠습니까?': '선택된 속성을 삭제하겠습니까?',
    '속성 추가': '속성 추가',
    'BOM 목록': 'BOM 목록',
    'BOM 추가': 'BOM 추가',
    '품목 사진': '품목 사진',
    '품목 추가에 성공하였습니다': '품목 추가에 성공하였습니다',
    '품목 수정에 성공하였습니다': '품목 수정에 성공하였습니다',
    '품목 삭제를 완료했습니다': '품목 삭제를 완료했습니다',
  },
  재고: {
    code: '품목 코드',
    name: '품명',
    itemType: '품목 종류',
    quantity: '현재 재고량',
    incoming: '입고 예정량',
    outgoing: '출고 예정량',
    forecasted: '예측 재고량',
    '재고 조정량': '재고 조정량',
    list: '재고 목록',
    history: '조정 이력',
    'itemData.name': '품명',
    'itemData.code': '품목 코드',
    'itemData.itemType': '품목 종류',
    'lotData.lotNumber': 'LOT 번호',
    countedQuantity: '조정 후 재고량',
    description: '비고',
    createdAt: '재고 조정 일시',
    '재고 조정': '재고 조정',
  },
  LOT: {
    'LOT 상세보기': 'LOT 상세보기',
    'LOT 정보': 'LOT 정보',
    'LOT 번호': 'LOT 번호',
    'LOT 입력': 'LOT 입력',
    'LOT 목록': 'LOT 목록',
    '주문 수량': '주문 수량',
    '입고 수량': '입고 수량',
    '사용 수량': '사용 수량',
    '출고 수량': '출고 수량',
    createdAt: '최초 등록 일시',
    updatedAt: '최근 수정 일시',
    lotNumber: 'LOT 번호',
    planCode: '작업 코드',
    'lotData.lotNumber': 'LOT 번호',
    countedQuantity: '조정 후 재고량',
    quantity: 'LOT 수량',
    품명: '품명',
    list: 'LOT 목록',
    history: '조정 이력',
    description: '비고',
    assignableQuantity: '사용 가능 수량',
    inputQuantity: '입력 수량',
    '재고 조정': '재고 조정',
    '현재 재고량': '현재 재고량',
    '재고 조정량': '재고 조정량',
    '조정 후 재고량': '조정 후 재고량',
    '재고 조정 일시': '재고 조정 일시',
    '품목 코드': '품목 코드',
    '품목 종류': '품목 종류',
    '작업 수량': '작업 수량',
    '작업 정보': '작업 정보',
  },
  BOM: {
    'BOM 등록': 'BOM 등록',
    'BOM 상세보기': 'BOM 상세보기',
    'BOM 정보': 'BOM 정보',
    id: 'BOM ID',
    name: 'BOM 제목',
    totalPrice: '생산 비용',
    description: '비고',
    비고: '비고',
    품목: '품목',
    가격: '가격',
    수량: '수량',
    '총 가격': '총 가격',
    '가격 합계': '가격 합계',
    '품목 코드': '품목 코드',
    'itemData.name': '품명',
    cost: '원가(생산가)',
    quantity: '사용 수량',
    eachItemTotalCost: '가격 합계',
    '자재 정보': '자재 정보',
    'BOM 삭제': 'BOM 삭제',
    '선택된 BOM을 삭제하겠습니까?': '선택된 BOM을 삭제하겠습니까?',
  },
  고객: {
    individual: '개인 고객',
    company: '업체 고객',
  },
  '드롭다운 제목': '{{field}} 변경',
  '(내 계정)': '(내 계정)',

  // device control

  '장비 제어 성공': '[{{keyName}}] 장비 제어에 성공했습니다',
  '장비 제어 실패': '[{{keyName}}] 장비 제어에 실패했습니다',
  '시간 설정': '시간 설정 [{{keyName}}]',
  '예약 설정': '예약 설정 [{{keyName}}]',

  Unassigned: '미할당',
  포트: '포트 {{portNo}}',
  '통신 연결 해제중': '{{deviceNickname}} 장비 통신 연결 해제중...',
  // router page
  protocol: '프로토콜',
  protocolId: '국번',
  portNo: '포트 번호',
  serverUrl: '서버 주소',
  serverCommPeriod: '서버 통신 주기',
  serverTimeout: '서버 타임아웃',
  serverRetryCount: '서버 통신 재시도',
  slaveCommPeriod: 'Slave 통신 주기',
  slaveTimeout: 'Slave 타임아웃',
  slaveRetryCount: 'Slave 통신 재시도',
  timeoutTooltip: '포트 {{portNumber}} {{keyName}}',

  단위: {
    월: '월',
    일: '일',
    시간: '시간',
    분: '분',
    단위: '단위',
  },
  날씨: {
    'thunderstorm with light rain': '약한 비를 동반한 천둥번개',
    'thunderstorm with rain': '보통 비를 동반한 천둥번개',
    'thunderstorm with heavy rain': '강한 비를 동반한 천둥번개',
    'light thunderstorm': '약한 천둥 번개',
    thunderstorm: '천둥 번개',
    'heavy thunderstorm': '강한 천둥 번개',
    'ragged thunderstorm': '불규칙한 천둥 번개',
    'thunderstorm with light drizzle': '약한 이슬비를 동반한 천둥번개',
    'thunderstorm with drizzle': '보통 이슬비를 동반한 천둥번개',
    'thunderstorm with heavy drizzle': '강한 이슬비를 동반한 천둥번개',
    'light intensity drizzle': '약한 이슬비',
    drizzle: '이슬비',
    'heavy intensity drizzle': '강한 이슬비',
    'light intensity drizzle rain': '약한 이슬비',
    'drizzle rain': '이슬비',
    'heavy intensity drizzle rain': '강한 이슬비',
    'shower rain and drizzle': '소나기와 이슬비',
    'heavy shower rain and drizzle': '강한 소나기와 이슬비',
    'shower drizzle': '이슬비',
    'light rain': '약한 비',
    'moderate rain': '비',
    'heavy intensity rain': '강한 비',
    'very heavy rain': '매우 강한 비',
    'extreme rain': '폭우',
    'freezing rain': '빙우',
    'light intensity shower rain': '약한 소나기',
    'shower rain': '소나기',
    'heavy intensity shower rain': '강한 소나기',
    'ragged shower rain': '불규칙한 소나기',
    'light snow': '약한 눈',
    snow: '눈',
    'heavy snow': '강한 눈',
    sleet: '진눈깨비',
    'light shower sleet': '약한 진눈깨비',
    'shower sleet': '진눈꺠비',
    'light rain and snow': '약한 비를 동반한 눈',
    'rain and snow': '비를 동반한 눈',
    'light shower snow': '약한 눈보라',
    'shower snow': '눈보라',
    'heavy shower snow': '강한 눈보라',
    mist: '안개',
    smoke: '스모그',
    haze: '아지랑이',
    'sand/dust whirls': '모래/먼지 회오리',
    fog: '짙은 안개',
    sand: '황사',
    dust: '미세 먼지',
    'volcanic ash': '화산 활동',
    squalls: '스콜',
    tornado: '토네이도',
    'clear sky': '맑음',
    'few clouds': '구름 조금',
    'scattered clouds': '구름 많음',
    'broken clouds': '흐림',
    'overcast clouds': '아주 흐림',
  },
  'YYYY-MM-DD': 'YYYY년 MM월 D일',
  '날짜 및 시간 표시 12시계': '{{dateFormatString}} A hh:mm:ss',
  '날짜 및 시간 표시 24시계': '{{dateFormatString}} HH:mm:ss',

  // 장비 추가

  'duplicate serial': '이미 등록된 시리얼 번호입니다.',
  'duplicate device nickname': '이미 등록된 장비 이름입니다.',
  'invalid type serial': '시리얼 번호가 유효하지 않습니다.',
  'serial is required': '빈 시리얼 번호 입니다.',
  'nickname is required': '빈 장비 이름 입니다.',

  '다중 장비 등록 에러':
    '[{{invalidRow}}번] 행 정보에 에러가 발생해 장비등록에 실패하였습니다. 에러 필드 행을 눌러 수정을 진행해주세요.',
  플레이스홀더: '새로 설정할 {{keyName}} 입력해주세요',
  소개: {
    소개: '소개',
    'Okomo 플랫폼과 함께': 'Okomo 플랫폼과 함께',
    '귀사의 사업 솔루션 설계를 시작하세요':
      '귀사의 사업 솔루션 설계를 시작하세요',
    '설비 구성에 도움이 필요하신가요? 엔지니어링 지원을 신청하세요,':
      '설비 구성에 도움이 필요하신가요? 엔지니어링 지원을 신청하세요,',
    '자사의 전문가가 최적의 솔루션을 제시해드립니다.':
      '자사의 전문가가 최적의 솔루션을 제시해드립니다.',
    '상담 신청': '상담 신청',
    'Okomo 체험하기': 'Okomo 체험하기',
    '회사 주소': '대한민국 경기도 안산시 상록구 안산대학로 155 BI 센터 503호',
    문의하기: '문의하기',
    '회사 소개': '회사 소개',
    '회사 번호': '070-8680-8368',
    '수집과 제어': '수집과 제어',
    '사용자 인터페이스 및 REST API를 활용하여 클라우드를 통해 IoT 설비를 연결하고 관리합니다. 통합 대시보드나 Grafana와 같은 서드 파티 툴을 사용하여 원격 측정 데이터를 수집하고 모니터링합니다.':
      '사용자 인터페이스 및 REST API를 활용하여 클라우드를 통해 IoT 설비를 연결하고 관리합니다. 통합 대시보드나 Grafana와 같은 서드 파티 툴을 사용하여 원격 측정 데이터를 수집하고 모니터링합니다.',
    '빠르고, 확장 가능하며, 현대적인': '빠르고, 확장 가능하며, 현대적인',
    'Kubernetes를 기반으로 구축한 IoT 마이크로서비스 아키텍처를 통해 필요한 만큼만 확장하거나 무한히 확장할 수 있으며, 이 모든 것이 최고의 성능과 내결함성을 갖추고 있습니다.':
      'Kubernetes를 기반으로 구축한 IoT 마이크로서비스 아키텍처를 통해 필요한 만큼만 확장하거나 무한히 확장할 수 있으며, 이 모든 것이 최고의 성능과 내결함성을 갖추고 있습니다.',
    '데이터를 통한 분석': '데이터를 통한 분석',
    '기본적으로 통합된 채널을 통해 IoT 설비의 데이터를 통합 데이터 분석 시스템으로 스트리밍합니다. 처리 결과에 따라 기기 동작을 변경할 수 있습니다.':
      '기본적으로 통합된 채널을 통해 IoT 설비의 데이터를 통합 데이터 분석 시스템으로 스트리밍합니다. 처리 결과에 따라 기기 동작을 변경할 수 있습니다.',
    '전문가들이 개발한': '전문가들이 개발한',
    'Okomo 플랫폼을 개발한 자사는 에너지, 통신, 빅데이터 분야에서 25년 이상의 경험을 보유하고 있습니다. 저희는 그동안 쌓아온 깊은 지식을 바탕으로 대기업과 소규모 스타트업을 모두 지원할 수 있는 최고의 플랫폼을 만들었습니다.':
      'Okomo 플랫폼을 개발한 자사는 에너지, 통신, 빅데이터 분야에서 25년 이상의 경험을 보유하고 있습니다. 저희는 그동안 쌓아온 깊은 지식을 바탕으로 대기업과 소규모 스타트업을 모두 지원할 수 있는 최고의 플랫폼을 만들었습니다.',
    '보안 & 사용자 관리': '보안 & 사용자 관리',
    '모든 데이터는 전송 및 저장 중에 암호화됩니다. 또한 Firebase 인증 시스템을 통한 사용자 기본 인증 및 권한 부여 기능을 제공합니다.':
      '모든 데이터는 전송 및 저장 중에 암호화됩니다. 또한 Firebase 인증 시스템을 통한 사용자 기본 인증 및 권한 부여 기능을 제공합니다.',
    '통합 제어': '통합 제어',
    'Okomo 플랫폼을 어디에 호스팅할지 선택할 수 있습니다. 공용 클라우드, 자체 서버, 또는 두 곳을 동시에 활용하는 멀티 클라우드 환경으로도 구성이 가능합니다.':
      'Okomo 플랫폼을 어디에 호스팅할지 선택할 수 있습니다. 공용 클라우드, 자체 서버, 또는 두 곳을 동시에 활용하는 멀티 클라우드 환경으로도 구성이 가능합니다.',
    '플랫폼 특징': '플랫폼 특징',
    '설비 관리': '설비 관리',
    '데이터 수집': '데이터 수집',
    '구성 관리': '구성 관리',
    '명령 실행': '명령 실행',
    '설비의 인증 정보를 개별적으로 또는 그룹 단위로 관리합니다. 디지털 트윈 레지스터를 적용하여 오프라인 상태에서도 설비의 모든 데이터를 사용할 수 있도록 합니다.':
      '설비의 인증 정보를 개별적으로 또는 그룹 단위로 관리합니다. 디지털 트윈 레지스터를 적용하여 오프라인 상태에서도 설비의 모든 데이터를 사용할 수 있도록 합니다.',
    '대규모 데이터를 안정적으로 수집하고, 효율적인 데이터 처리 구성 시스템을 제공하며, 배칭으로 네트워크 및 배터리 사용을 최적화합니다. Okomo 플랫폼은 BigQuery 등과 같은 프로덕션에 적합한 데이터베이스와 사전 통합되어 있습니다.':
      '대규모 데이터를 안정적으로 수집하고, 효율적인 데이터 처리 구성 시스템을 제공하며, 배칭으로 네트워크 및 배터리 사용을 최적화합니다. Okomo 플랫폼은 BigQuery 등과 같은 프로덕션에 적합한 데이터베이스와 사전 통합되어 있습니다.',
    '구성 관리는 설비 제어, 데이터 처리 매개변수 관리, 엣지 분석, 기능 활성화 지정과 같은 전반적인 기능을 위한 필수 요소입니다. 예를 들어, 냉동 창고를 원하는 온도로 설정하거나 게이트웨이의 통신 주기를 설정하는 등의 작업을 수행할 수 있습니다.':
      '구성 관리는 설비 제어, 데이터 처리 매개변수 관리, 엣지 분석, 기능 활성화 지정과 같은 전반적인 기능을 위한 필수 요소입니다. 예를 들어, 냉동 창고를 원하는 온도로 설정하거나 게이트웨이의 통신 주기를 설정하는 등의 작업을 수행할 수 있습니다.',
    '명령 실행 기능을 통해 페이로드를 포함한 메시지를 연결된 기기로 전송하고, 명령을 실행하며 거의 실시간으로 응답을 받을 수 있습니다. 예를 들어, 냉장고의 온도 조절기에서 현재 온도를 원격으로 확인하거나 전기차 충전기를 재부팅 할 수 있습니다.':
      '명령 실행 기능을 통해 페이로드를 포함한 메시지를 연결된 기기로 전송하고, 명령을 실행하며 거의 실시간으로 응답을 받을 수 있습니다. 예를 들어, 냉장고의 온도 조절기에서 현재 온도를 원격으로 확인하거나 전기차 충전기를 재부팅 할 수 있습니다.',
    '플랫폼의 장점': '플랫폼의 장점',
    'Okomo 플랫폼은 대규모, 엔터프라이즈급 에너지 솔루션을 염두에 두고 설계되었습니다. 이 플랫폼은 기존의 모놀리식 아키텍처 접근 방식을 배제하고, 유연하게 구성 변경 및 조정이나 맞춤 설정이 가능한 고도로 이식성 있는 마이크로서비스를 채택했습니다. 이를 통해 플랫폼을 사용하면서도 원하는 구성을 언제든지 자유롭게 변경할 수 있습니다. 또한, 클라우드 네이티브 확장성과 복원성을 지원하며, 배포 방식에 대한 완전한 자유를 제공합니다. 기술 개발과 DevOps에 있어 특정 기술에 구애받지 않는 접근 방식을 결합하여 Okomo 플랫폼은 기술, 배포 모델, 맞춤화의 광범위한 자유를 제공함으로써 귀사의 에너지 혁신을 주도할 수 있도록 합니다.':
      'Okomo 플랫폼은 대규모, 엔터프라이즈급 에너지 솔루션을 염두에 두고 설계되었습니다. 이 플랫폼은 기존의 모놀리식 아키텍처 접근 방식을 배제하고, 유연하게 구성 변경 및 조정이나 맞춤 설정이 가능한 고도로 이식성 있는 마이크로서비스를 채택했습니다. 이를 통해 플랫폼을 사용하면서도 원하는 구성을 언제든지 자유롭게 변경할 수 있습니다. 또한, 클라우드 네이티브 확장성과 복원성을 지원하며, 배포 방식에 대한 완전한 자유를 제공합니다. 기술 개발과 DevOps에 있어 특정 기술에 구애받지 않는 접근 방식을 결합하여 Okomo 플랫폼은 기술, 배포 모델, 맞춤화의 광범위한 자유를 제공함으로써 귀사의 에너지 혁신을 주도할 수 있도록 합니다.',
    'B2B 에너지 서비스': 'B2B 에너지 서비스',
    '전문적인 서비스': '전문적인 서비스',
    '저희는 종합 에너지 서비스 기업으로, Okomo 플랫폼과 업계 특화 에너지 기술을 기반으로 에너지 개발 및 통합 서비스를 제공합니다. 에너지 분야뿐만 아니라 빅데이터, 클라우드, 임베디드, 블록체인, 머신러닝 등 현대적인 기술 영역에서도 깊은 전문성을 보유하고 있습니다. Okomo 플랫폼은 원래 CyberVision Inc.의 프로젝트 중 하나로 시작되었으며, 빠르게 발전해 독립적인 회사로 분리되어 Okomo에 집중하게 되었습니다. CyberVision과 Okomo 플랫폼의 25년 이상의 경험을 결합하여 시장에 빠르게 진출할 수 있도록 돕는 풀스택 에너지 구현 서비스를 제공합니다.':
      '저희는 종합 에너지 서비스 기업으로, Okomo 플랫폼과 업계 특화 에너지 기술을 기반으로 에너지 개발 및 통합 서비스를 제공합니다. 에너지 분야뿐만 아니라 빅데이터, 클라우드, 임베디드, 블록체인, 머신러닝 등 현대적인 기술 영역에서도 깊은 전문성을 보유하고 있습니다. Okomo 플랫폼은 원래 CyberVision Inc.의 프로젝트 중 하나로 시작되었으며, 빠르게 발전해 독립적인 회사로 분리되어 Okomo에 집중하게 되었습니다. CyberVision과 Okomo 플랫폼의 25년 이상의 경험을 결합하여 시장에 빠르게 진출할 수 있도록 돕는 풀스택 에너지 구현 서비스를 제공합니다.',
    '귀사의 특별한 문제나 요구 사항이 있으시면 언제든지 저희에게 연락주세요. 기꺼이 도움을 드리겠습니다.':
      '귀사의 특별한 문제나 요구 사항이 있으시면 언제든지 저희에게 연락주세요. 기꺼이 도움을 드리겠습니다.',
    '비즈니스 유형 분석': '비즈니스 유형 분석',
    '요구사항 실현을 위한 시범 구축': '요구사항 실현을 위한 시범 구축',
    '프로젝트 진행과 목표 달성': '프로젝트 진행과 목표 달성',
    '최종 솔루션 도입': '최종 솔루션 도입',
    '사후 지원 서비스': '사후 지원 서비스',
    '시작할 준비가 됐나요?': '시작할 준비가 됐나요?',
    '체험판 Okomo 계정을 생성하거나 전문가의 도움을 받아 무료 체험을 신청하세요.':
      '체험판 Okomo 계정을 생성하거나 전문가의 도움을 받아 무료 체험을 신청하세요.',
    'Okomo 이용하기': 'Okomo 이용하기',
  },
  문의: {
    lastName: '성',
    firstName: '이름',
    'lastName 입력해 주세요': '성을 입력해주세요',
    'firstName 입력해 주세요': '이름을 입력해주세요',
    회사: '회사',
    이메일: '이메일',
    '휴대 전화번호': '휴대 전화번호',
    '문의 종류': '문의 종류',
    '상담 신청': '상담 신청',
    문의사항: '문의사항',
    문의하기: '문의하기',
    '회사를 입력해 주세요': '회사를 입력해 주세요',
    '문의사항을 입력해 주세요': '문의사항을 입력해 주세요',
    '이메일을 입력해 주세요': '이메일을 입력해 주세요',
    '휴대 전화번호를 입력해 주세요': '휴대 전화번호를 입력해 주세요',
    '무엇을 도와드릴까요? 서비스 이용에 대한 문의사항을 남겨주세요':
      '무엇을 도와드릴까요? 서비스 이용에 대한 문의사항을 남겨주세요',
    '영업 문의': '영업 문의',
    '계정 관련': '계정 관련',
    '기술 지원': '기술 지원',
    '파트너쉽 문의': '파트너쉽 문의',
    '언론 및 미디어': '언론 및 미디어',
    기타: '기타',
    '약관에 동의해 주세요': '약관에 동의해 주세요',
    '문의 주셔서 감사합니다. 빠른 시일 내에 답변드리겠습니다.':
      '문의 주셔서 감사합니다. 빠른 시일 내에 답변드리겠습니다.',
    createdAt: '문의 일자',
    categoryName: '문의 종류',
    name: '이름',
    company: '회사',
    content: '문의 내용',
  },
};

export default koJSON;
