import { useRef, useState } from 'react';
import { List, ListItem, ListItemText, Popover, Tooltip } from '@mui/material';
import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { KR } from 'country-flag-icons/react/3x2';
import { US } from 'country-flag-icons/react/3x2';
import {
  SectionHeading,
  IconButtonWrapper,
  ModalWrapper,
  CountryIconWrapper,
} from './style.js';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getLanguage = i18n.language;

  const switchLanguage = ({ lng }) => {
    localStorage.setItem('language', lng);
    internationalization.changeLanguage(lng);
  };
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('언어 변환')}>
        <IconButtonWrapper color="secondary" ref={ref} onClick={handleOpen}>
          <CountryIconWrapper>
            {getLanguage === 'ko' && <KR title="Korea" />}
            {getLanguage === 'en' && <US title="English" />}
          </CountryIconWrapper>
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ModalWrapper>
          <SectionHeading variant="body2" color="text.primary">
            {t('언어 변환')}
          </SectionHeading>
          <List className="button_box" component="nav">
            <ListItem
              className={getLanguage === 'ko' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'ko' });
                handleClose();
              }}
            >
              <KR title="Korea" />
              <ListItemText className="list_text" primary={t('한국어')} />
            </ListItem>
            <ListItem
              className={
                getLanguage === 'en' || getLanguage === 'en-US' ? 'active' : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
            >
              <US title="English" />
              <ListItemText className="list_text" primary={t('영어')} />
            </ListItem>
          </List>
        </ModalWrapper>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
