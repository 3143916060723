import { LogoWrapper } from './style';
import { Box } from '@mui/material';
import Logo from 'src/components/Logo/index';
import { LinearProgress } from '@mui/material';

const AppInit = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LogoWrapper>
        <Box className="logo_box" justifyContent="center">
          <Logo forceDarkLogo />
        </Box>
        <LinearProgress />
      </LogoWrapper>
    </Box>
  );
};

export default AppInit;
