import React from 'react';
import classNames from 'classnames';
import featureTileIcon03 from 'src/pages/information/assets/images/feature-tile-icon-03.svg';
import featureTileIcon06 from 'src/pages/information/assets/images/feature-tile-icon-06.svg';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { FeaturesTilesH4, FeaturesTilesP, FeaturesTilesDiv } from '../style';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left',
  );

  const sectionHeader = {
    title: 'In Okomo...',
    paragraph: 'The power of Okomo',
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container" data-reveal-delay="800">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-reveal-delay="200"
          />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FeaturesTilesDiv className="mb-16">
                    <Image
                      src={featureTileIcon03}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </FeaturesTilesDiv>
                </div>
                <div className="features-tiles-item-content">
                  <FeaturesTilesH4 className="mt-0 mb-8">
                    Monitoring
                  </FeaturesTilesH4>
                  <FeaturesTilesP className="m-0 text-sm">
                    Real-time Monitoring Available
                  </FeaturesTilesP>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <FeaturesTilesDiv className="mb-16">
                    <Image
                      src={featureTileIcon06}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </FeaturesTilesDiv>
                </div>
                <div className="features-tiles-item-content">
                  <FeaturesTilesH4 className="mt-0 mb-8">
                    Managing MES
                  </FeaturesTilesH4>
                  <FeaturesTilesP className="m-0 text-sm">
                    Traking your manufacturing status
                  </FeaturesTilesP>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
