import { Box, styled } from '@mui/material';

export const LogoWrapper = styled(Box)(
  ({ theme }) => `      
      .logo_box {
        margin-left: 15px;
        margin-bottom: 12px;
        width : 130px;
        height: 35px;
      }
        
      .MuiChip-root {        
        background: #5E90cc;
        margin-bottom: -11px;
        margin-right: -12px;
        height: 14px;
        width: 30px;
        color: ${theme.palette.primary.contrastText};
        font-size: 10px;
        text-align: center;
        font-weight: bold;
  
        span {
          padding: 2px;
        }
      }
  `,
);
