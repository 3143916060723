import {
  AD,
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AO,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BL,
  BM,
  BN,
  BO,
  BQ,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CC,
  CD,
  CF,
  CG,
  CH,
  CI,
  CK,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CW,
  CX,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  FI,
  FJ,
  FK,
  FM,
  FO,
  FR,
  GA,
  GB,
  GD,
  GE,
  GF,
  GG,
  GH,
  GI,
  GL,
  GM,
  GN,
  GP,
  GQ,
  GR,
  GS,
  GT,
  GU,
  GW,
  GY,
  HK,
  HM,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IO,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MF,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MO,
  MP,
  MQ,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NC,
  NE,
  NF,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PM,
  PN,
  PR,
  PS,
  PT,
  PW,
  PY,
  QA,
  RE,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SJ,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SX,
  SY,
  SZ,
  TA,
  TC,
  TD,
  TG,
  TH,
  TJ,
  TK,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TW,
  TZ,
  UA,
  UG,
  UM,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VG,
  VI,
  VN,
  VU,
  WF,
  WS,
  XK,
  YE,
  YT,
  ZA,
  ZM,
  ZW,
} from 'country-flag-icons/react/3x2';

export const countries = {
  AD: {
    name: '안도라',
    image: <AD title="안도라" />,
    phone: '376',
  },
  AE: {
    name: '아랍에미리트',
    image: <AE title="아랍에미리트" />,
    phone: '971',
  },

  AF: {
    name: '아프가니스탄',
    image: <AF title="아프가니스탄" />,
    phone: '93',
  },
  AG: {
    name: '앤티가 바부다',
    image: <AG title="앤티가 바부다" />,
    phone: '1-268',
  },
  AI: {
    name: '앵귈라',
    image: <AI title="앵귈라" />,
    phone: '1-264',
  },
  AL: {
    name: '알바니아',
    image: <AL title="알바니아" />,
    phone: '355',
  },
  AM: {
    name: '아르메니아',
    image: <AM title="아르메니아" />,
    phone: '374',
  },
  AO: {
    name: '앙골라',
    image: <AO title="앙골라" />,
    phone: '244',
  },
  AQ: {
    name: '남극',
    image: <AQ title="남극" />,
    phone: '672',
  },

  AR: {
    name: '아르헨티나',
    image: <AR title="아르헨티나" />,
    phone: '54',
  },
  AS: {
    name: '아메리칸사모아',
    image: <AS title="아메리칸사모아" />,
    phone: '1-684',
  },
  AT: {
    name: '오스트리아',
    image: <AT title="오스트리아" />,
    phone: '43',
  },
  AU: {
    name: '호주',
    image: <AU title="호주" />,
    phone: '61',
  },
  AW: {
    name: '아루바',
    image: <AW title="아루바" />,
    phone: '297',
  },
  AX: {
    name: '올란드 제도',
    image: <AX title="올란드 제도" />,
    phone: '358',
  },
  AZ: {
    name: '아제르바이잔',
    image: <AZ title="아제르바이잔" />,
    phone: '994',
  },
  BA: {
    name: '보스니아 헤르체고비나',
    image: <BA title="보스니아 헤르체고비나" />,
    phone: '387',
  },
  BB: {
    name: '바베이도스',
    image: <BB title="바베이도스" />,
    phone: '1-246',
  },
  BD: {
    name: '방글라데시',
    image: <BD title="방글라데시" />,
    phone: '880',
  },
  BE: {
    name: '벨기에',
    image: <BE title="벨기에" />,
    phone: '32',
  },
  BF: {
    name: '부르키나파소',
    image: <BF title="부르키나파소" />,
    phone: '226',
  },
  BG: {
    name: '불가리아',
    image: <BG title="불가리아" />,
    phone: '359',
  },

  BH: {
    name: '바레인',
    image: <BH title="바레인" />,
    phone: '973',
  },
  BI: {
    name: '브룬디',
    image: <BI title="브룬디" />,
    phone: '257',
  },
  BJ: {
    name: '베냉',
    image: <BJ title="베냉" />,
    phone: '229',
  },
  BL: {
    name: '세인트 바르탤르미',
    image: <BL title="세인트 바르탤르미" />,
    phone: '590',
  },
  BM: {
    name: '버뮤다',
    image: <BM title="버뮤다" />,
    phone: '1-441',
  },
  BN: {
    name: '브루나이',
    image: <BN title="브루나이" />,
    phone: '673',
  },
  BO: {
    name: '볼리비아',
    image: <BO title="볼리비아" />,
    phone: '591',
  },
  BQ: {
    name: '보네르',
    image: <BQ title="보네르" />,
    phone: '599',
  },
  BR: {
    name: '브라질',
    image: <BR title="브라질" />,
    phone: '55',
  },
  BS: {
    name: '바하마',
    image: <BS title="바하마" />,
    phone: '1-242',
  },
  BT: {
    name: '부탄',
    image: <BT title="부탄" />,
    phone: '975',
  },

  BW: {
    name: '보츠와나',
    image: <BW title="보츠와나" />,
    phone: '267',
  },

  BY: {
    name: '벨라루스',
    image: <BY title="벨라루스" />,
    phone: '375',
  },
  BZ: {
    name: '벨리즈',
    image: <BZ title="벨리즈" />,
    phone: '501',
  },
  CA: {
    name: '캐나다',
    image: <CA title="캐나다" />,
    phone: '1',
  },
  CC: {
    name: '코코스 제도',
    image: <CC title="코코스 제도" />,
    phone: '61',
  },
  CD: {
    name: '콩고 민주 공화국',
    image: <CD title="콩고 민주 공화국" />,
    phone: '243',
  },
  CF: {
    name: '중앙아프리카 공화국',
    image: <CF title="중앙아프리카 공화국" />,
    phone: '236',
  },
  CG: {
    name: '콩고',
    image: <CG title="콩고" />,
    phone: '242',
  },
  CH: {
    name: '스위스',
    image: <CH title="스위스" />,
    phone: '41',
  },
  CI: {
    name: '코트디부아르',
    image: <CI title="코트디부아르" />,
    phone: '225',
  },
  CK: {
    name: '쿡 제도',
    image: <CK title="쿡 제도" />,
    phone: '682',
  },
  CL: {
    name: '칠레',
    image: <CL title="칠레" />,
    phone: '56',
  },
  CM: {
    name: '카메룬',
    image: <CM title="카메룬" />,
    phone: '237',
  },
  CN: {
    name: '중국',
    image: <CN title="중국" />,
    phone: '86',
  },
  CO: {
    name: '콜롬비아',
    image: <CO title="콜롬비아" />,
    phone: '57',
  },
  CR: {
    name: '코스타리카',
    image: <CR title="코스타리카" />,
    phone: '506',
  },
  CU: {
    name: '쿠바',
    image: <CU title="쿠바" />,
    phone: '53',
  },
  CV: {
    name: '카보베르데',
    image: <CV title="카보베르데" />,
    phone: '238',
  },
  CW: {
    name: '큐라소',
    image: <CW title="큐라소" />,
    phone: '599',
  },
  CX: {
    name: '크리스마스섬',
    image: <CX title="크리스마스섬" />,
    phone: '61',
  },
  CY: {
    name: '사이프러스',
    image: <CY title="사이프러스" />,
    phone: '357',
  },

  CZ: {
    name: '체코공화국',
    image: <CZ title="체코공화국" />,
    phone: '420',
  },
  DE: {
    name: '독일',
    image: <DE title="독일" />,
    phone: '49',
  },
  DJ: {
    name: '지부티',
    image: <DJ title="지부티" />,
    phone: '253',
  },
  DK: {
    name: '덴마크',
    image: <DK title="덴마크" />,
    phone: '45',
  },
  DM: {
    name: '도미니카',
    image: <DM title="도미니카" />,
    phone: '1-767',
  },
  DO: {
    name: '도미니카 공화국',
    image: <DO title="도미니카 공화국" />,
    phone: '1-809, 1-829, 1-849',
  },
  DZ: {
    name: '알제리',
    image: <DZ title="알제리" />,
    phone: '213',
  },
  EC: {
    name: '에콰도르',
    image: <EC title="에콰도르" />,
    phone: '593',
  },
  EE: {
    name: '에스토니아',
    image: <EE title="에스토니아" />,
    phone: '372',
  },
  EG: {
    name: '이집트',
    image: <EG title="이집트" />,
    phone: '20',
  },
  EH: {
    name: '서사하라',
    image: <EH title="서사하라" />,
    phone: '212',
  },
  ER: {
    name: '에리트레아',
    image: <ER title="에리트레아" />,
    phone: '291',
  },
  ES: {
    name: '스페인',
    image: <ES title="스페인" />,
    phone: '34',
  },
  ET: {
    name: '이디오피아',
    image: <ET title="이디오피아" />,
    phone: '251',
  },
  FI: {
    name: '핀란드',
    image: <FI title="핀란드" />,
    phone: '358',
  },
  FJ: {
    name: '피지',
    image: <FJ title="피지" />,
    phone: '679',
  },
  FK: {
    name: '포클랜드섬',
    image: <FK title="포클랜드섬" />,
    phone: '500',
  },
  FM: {
    name: '미크로네시아',
    image: <FM title="미크로네시아" />,
    phone: '691',
  },
  FO: {
    name: '페로 군도',
    image: <FO title="페로 군도" />,
    phone: '298',
  },
  FR: {
    name: '프랑스',
    image: <FR title="프랑스" />,
    phone: '33',
  },

  GA: {
    name: '가봉',
    image: <GA title="가봉" />,
    phone: '241',
  },
  GB: {
    name: '영국',
    image: <GB title="영국" />,
    phone: '44',
  },
  GD: {
    name: '그레나다',
    image: <GD title="그레나다" />,
    phone: '1-473',
  },
  GE: {
    name: '구루지아',
    image: <GE title="구루지아" />,
    phone: '995',
  },
  GF: {
    name: '프랑스령 기아나',
    image: <GF title="프랑스령 기아나" />,
    phone: '594',
  },
  GG: {
    name: '건지',
    image: <GG title="건지" />,
    phone: '44-1481',
  },
  GH: {
    name: '가나',
    image: <GH title="가나" />,
    phone: '233',
  },
  GI: {
    name: '지브랄타',
    image: <GI title="지브랄타" />,
    phone: '350',
  },
  GL: {
    name: '그린랜드',
    image: <GL title="그린랜드" />,
    phone: '299',
  },
  GM: {
    name: '감비아',
    image: <GM title="감비아" />,
    phone: '220',
  },
  GN: {
    name: '기니',
    image: <GN title="기니" />,
    phone: '224',
  },
  GP: {
    name: '과들루프',
    image: <GP title="과들루프" />,
    phone: '590',
  },
  GQ: {
    name: '적도 기니',
    image: <GQ title="적도 기니" />,
    phone: '240',
  },
  GR: {
    name: '그리스',
    image: <GR title="그리스" />,
    phone: '30',
  },
  GS: {
    name: '사우스조지아 사우스샌드위치 제도',
    image: <GS title="사우스조지아 사우스샌드위치 제도" />,
    phone: '500',
  },
  GT: {
    name: '과테말라',
    image: <GT title="과테말라" />,
    phone: '502',
  },
  GU: {
    name: '괌',
    image: <GU title="괌" />,
    phone: '1-671',
  },
  GW: {
    name: '기네비쏘',
    image: <GW title="기네비쏘" />,
    phone: '245',
  },
  GY: {
    name: '가이아나',
    image: <GY title="가이아나" />,
    phone: '592',
  },
  HK: {
    name: '홍콩',
    image: <HK title="홍콩" />,
    phone: '852',
  },

  HM: {
    name: '허드 맥도날드 군도',
    image: <HM title="허드 맥도날드 군도" />,
    phone: '672',
  },
  HN: {
    name: '온두라스',
    image: <HN title="온두라스" />,
    phone: '504',
  },
  HR: {
    name: '크로아티아',
    image: <HR title="크로아티아" />,
    phone: '385',
  },
  HT: {
    name: '아이티',
    image: <HT title="아이티" />,
    phone: '509',
  },
  HU: {
    name: '헝가리',
    image: <HU title="헝가리" />,
    phone: '36',
  },
  ID: {
    name: '인도네시아',
    image: <ID title="인도네시아" />,
    phone: '62',
  },
  IE: {
    name: '아일랜드',
    image: <IE title="아일랜드" />,
    phone: '353',
  },
  IL: {
    name: '이스라엘',
    image: <IL title="이스라엘" />,
    phone: '972',
  },
  IM: {
    name: '맨섬',
    image: <IM title="맨섬" />,
    phone: '44-1624',
  },
  IN: {
    name: '인도',
    image: <IN title="인도" />,
    phone: '91',
  },
  IO: {
    name: '영인도 제도',
    image: <IO title="영인도 제도" />,
    phone: '246',
  },
  IQ: {
    name: '이라크',
    image: <IQ title="이라크" />,
    phone: '964',
  },
  IR: {
    name: '이란',
    image: <IR title="이란" />,
    phone: '98',
  },
  IS: {
    name: '아이슬랜드',
    image: <IS title="아이슬랜드" />,
    phone: '354',
  },
  IT: {
    name: '이탈리아',
    image: <IT title="이탈리아" />,
    phone: '39',
  },
  JE: {
    name: '저지',
    image: <JE title="저지" />,
    phone: '44-1534',
  },
  JM: {
    name: '자메이카',
    image: <JM title="자메이카" />,
    phone: '1-876',
  },
  JO: {
    name: '요르단',
    image: <JO title="요르단" />,
    phone: '962',
  },
  JP: {
    name: '일본',
    image: <JP title="일본" />,
    phone: '81',
  },
  KE: {
    name: '케냐',
    image: <KE title="케냐" />,
    phone: '254',
  },

  KG: {
    name: '키르기스스탄',
    image: <KG title="키르기스스탄" />,
    phone: '996',
  },
  KH: {
    name: '캄보디아',
    image: <KH title="캄보디아" />,
    phone: '855',
  },
  KI: {
    name: '키리바시',
    image: <KI title="키리바시" />,
    phone: '686',
  },
  KM: {
    name: '코모로',
    image: <KM title="코모로" />,
    phone: '269',
  },
  KN: {
    name: '세인트키츠 네비스',
    image: <KN title="세인트키츠 네비스" />,
    phone: '1-869',
  },
  KP: {
    name: '북한',
    image: <KP title="북한" />,
    phone: '850',
  },
  KR: {
    name: '대한민국',
    image: <KR title="대한민국" />,
    phone: '82',
    phoneDigitLimit: 11,
    language: 'ko',
  },
  KW: {
    name: '쿠웨이트',
    image: <KW title="쿠웨이트" />,
    phone: '965',
  },
  KY: {
    name: '케이맨 제도',
    image: <KY title="케이맨 제도" />,
    phone: '1-345',
  },
  KZ: {
    name: '카자흐스탄',
    image: <KZ title="카자흐스탄" />,
    phone: '7',
  },
  LA: {
    name: '라오스',
    image: <LA title="라오스" />,
    phone: '856',
  },
  LB: {
    name: '레바논',
    image: <LB title="레바논" />,
    phone: '961',
  },
  LC: {
    name: '세인트루시아',
    image: <LC title="세인트루시아" />,
    phone: '1-758',
  },
  LI: {
    name: '리히텐슈타인',
    image: <LI title="리히텐슈타인" />,
    phone: '423',
  },
  LK: {
    name: '스리랑카',
    image: <LK title="스리랑카" />,
    phone: '94',
  },
  LR: {
    name: '라이베리아',
    image: <LR title="라이베리아" />,
    phone: '231',
  },
  LS: {
    name: '레소토',
    image: <LS title="레소토" />,
    phone: '266',
  },
  LT: {
    name: '리투아니아',
    image: <LT title="리투아니아" />,
    phone: '370',
  },
  LU: {
    name: '룩셈부르크',
    image: <LU title="룩셈부르크" />,
    phone: '352',
  },

  LV: {
    name: '라트비아',
    image: <LV title="라트비아" />,
    phone: '371',
  },
  LY: {
    name: '리비아',
    image: <LY title="리비아" />,
    phone: '218',
  },
  MA: {
    name: '모로코',
    image: <MA title="모로코" />,
    phone: '212',
  },
  MC: {
    name: '모나코',
    image: <MC title="모나코" />,
    phone: '377',
  },
  MD: {
    name: '몰도바',
    image: <MD title="몰도바" />,
    phone: '373',
  },
  ME: {
    name: '몬테네그로',
    image: <ME title="몬테네그로" />,
    phone: '382',
  },
  MF: {
    name: '세인트 마틴',
    image: <MF title="세인트 마틴" />,
    phone: '590',
  },
  MG: {
    name: '마다가스카르',
    image: <MG title="마다가스카르" />,
    phone: '261',
  },
  MH: {
    name: '마샬군도',
    image: <MH title="마샬군도" />,
    phone: '692',
  },
  MK: {
    name: '마케도니아',
    image: <MK title="마케도니아" />,
    phone: '389',
  },
  ML: {
    name: '말리',
    image: <ML title="말리" />,
    phone: '223',
  },
  MM: {
    name: '미얀마',
    image: <MM title="미얀마" />,
    phone: '95',
  },
  MN: {
    name: '몽골',
    image: <MN title="몽골" />,
    phone: '976',
  },
  MO: {
    name: '마카오',
    image: <MO title="마카오" />,
    phone: '853',
  },
  MP: {
    name: '북마리아나 제도',
    image: <MP title="북마리아나 제도" />,
    phone: '1-670',
  },
  MQ: {
    name: '마르티니크',
    image: <MQ title="마르티니크" />,
    phone: '596',
  },
  MR: {
    name: '모리타니',
    image: <MR title="모리타니" />,
    phone: '222',
  },
  MS: {
    name: '몬트세라트',
    image: <MS title="몬트세라트" />,
    phone: '1-664',
  },
  MT: {
    name: '말타',
    image: <MT title="말타" />,
    phone: '356',
  },

  MU: {
    name: '모리셔스',
    image: <MU title="모리셔스" />,
    phone: '230',
  },
  MV: {
    name: '몰디브',
    image: <MV title="몰디브" />,
    phone: '960',
  },
  MW: {
    name: '말라위',
    image: <MW title="말라위" />,
    phone: '265',
  },
  MX: {
    name: '멕시코',
    image: <MX title="멕시코" />,
    phone: '52',
  },
  MY: {
    name: '말레이시아',
    image: <MY title="말레이시아" />,
    phone: '60',
  },
  MZ: {
    name: '모잠비크',
    image: <MZ title="모잠비크" />,
    phone: '258',
  },
  NA: {
    name: '나미비아',
    image: <NA title="나미비아" />,
    phone: '264',
  },
  NC: {
    name: '뉴칼레도니아',
    image: <NC title="뉴칼레도니아" />,
    phone: '687',
  },
  NE: {
    name: '니제르',
    image: <NE title="니제르" />,
    phone: '227',
  },
  NF: {
    name: '노퍽섬',
    image: <NF title="노퍽섬" />,
    phone: '672',
  },
  NG: {
    name: '나이지리아',
    image: <NG title="나이지리아" />,
    phone: '234',
  },
  NI: {
    name: '니카라과',
    image: <NI title="니카라과" />,
    phone: '505',
  },
  NL: {
    name: '네덜란드',
    image: <NL title="네덜란드" />,
    phone: '31',
  },
  NO: {
    name: '노르웨이',
    image: <NO title="노르웨이" />,
    phone: '47',
  },

  NP: {
    name: '네팔',
    image: <NP title="네팔" />,
    phone: '977',
  },
  NR: {
    name: '나우루',
    image: <NR title="나우루" />,
    phone: '674',
  },
  NU: {
    name: '니우에',
    image: <NU title="니우에" />,
    phone: '683',
  },
  NZ: {
    name: '뉴질랜드',
    image: <NZ title="뉴질랜드" />,
    phone: '64',
  },
  OM: {
    name: '오만',
    image: <OM title="오만" />,
    phone: '968',
  },
  PA: {
    name: '파나마',
    image: <PA title="파나마" />,
    phone: '507',
  },
  PE: {
    name: '페루',
    image: <PE title="페루" />,
    phone: '51',
  },
  PF: {
    name: '프랑스령 폴리네시아',
    image: <PF title="프랑스령 폴리네시아" />,
    phone: '689',
  },
  PG: {
    name: '파푸아뉴기니',
    image: <PG title="파푸아뉴기니" />,
    phone: '675',
  },
  PH: {
    name: '필리핀',
    image: <PH title="필리핀" />,
    phone: '63',
  },
  PK: {
    name: '파키스탄',
    image: <PK title="파키스탄" />,
    phone: '92',
  },
  PL: {
    name: '폴란드',
    image: <PL title="폴란드" />,
    phone: '48',
  },
  PM: {
    name: '생피에르 미클롱',
    image: <PM title="생피에르 미클롱" />,
    phone: '508',
  },
  PN: {
    name: '핏케언 제도',
    image: <PN title="핏케언 제도" />,
    phone: '64',
  },
  PR: {
    name: '푸에르토리코',
    image: <PR title="푸에르토리코" />,
    phone: '1',
  },
  PS: {
    name: '팔레스타인',
    image: <PS title="팔레스타인" />,
    phone: '970',
  },
  PT: {
    name: '포르투갈',
    image: <PT title="포르투갈" />,
    phone: '351',
  },
  PW: {
    name: '팔라우',
    image: <PW title="팔라우" />,
    phone: '680',
  },

  PY: {
    name: '파라과이',
    image: <PY title="파라과이" />,
    phone: '595',
  },
  QA: {
    name: '카타르',
    image: <QA title="카타르" />,
    phone: '974',
  },
  RE: {
    name: '레위니옹',
    image: <RE title="레위니옹" />,
    phone: '262',
  },
  RO: {
    name: '루마니아',
    image: <RO title="루마니아" />,
    phone: '40',
  },
  RS: {
    name: '세르비아',
    image: <RS title="세르비아" />,
    phone: '381',
  },
  RU: {
    name: '러시아',
    image: <RU title="러시아" />,
    phone: '7',
  },
  RW: {
    name: '르완다',
    image: <RW title="르완다" />,
    phone: '250',
  },
  SA: {
    name: '사우디아라비아',
    image: <SA title="사우디아라비아" />,
    phone: '966',
  },
  SB: {
    name: '솔로몬 제도',
    image: <SB title="솔로몬 제도" />,
    phone: '677',
  },
  SC: {
    name: '세이셸',
    image: <SC title="세이셸" />,
    phone: '248',
  },
  SD: {
    name: '수단',
    image: <SD title="수단" />,
    phone: '249',
  },
  SE: {
    name: '스웨덴',
    image: <SE title="스웨덴" />,
    phone: '46',
  },
  SG: {
    name: '싱가포르',
    image: <SG title="싱가포르" />,
    phone: '65',
  },
  SH: {
    name: '세인트헬레나',
    image: <SH title="세인트헬레나" />,
    phone: '290',
  },
  SI: {
    name: '슬로베니아',
    image: <SI title="슬로베니아" />,
    phone: '386',
  },

  SJ: {
    name: '스발바르와 얀 마옌',
    image: <SJ title="스발바르와 얀 마옌" />,
    phone: '47',
  },
  SK: {
    name: '슬로바키아',
    image: <SK title="슬로바키아" />,
    phone: '421',
  },
  SL: {
    name: '시에라리온',
    image: <SL title="시에라리온" />,
    phone: '232',
  },
  SM: {
    name: '산마리노',
    image: <SM title="산마리노" />,
    phone: '378',
  },
  SN: {
    name: '세네갈',
    image: <SN title="세네갈" />,
    phone: '221',
  },
  SO: {
    name: '소말리아',
    image: <SO title="소말리아" />,
    phone: '252',
  },
  SR: {
    name: '수리남',
    image: <SR title="수리남" />,
    phone: '597',
  },
  SS: {
    name: '남수단',
    image: <SS title="남수단" />,
    phone: '211',
  },
  ST: {
    name: '상투메 프린시페',
    image: <ST title="상투메 프린시페" />,
    phone: '239',
  },
  SV: {
    name: '엘살바도르',
    image: <SV title="엘살바도르" />,
    phone: '503',
  },
  SX: {
    name: '신트마르턴',
    image: <SX title="신트마르턴" />,
    phone: '1-721',
  },
  SY: {
    name: '시리아',
    image: <SY title="시리아" />,
    phone: '963',
  },
  SZ: {
    name: '에스와티니',
    image: <SZ title="에스와티니" />,
    phone: '268',
  },
  TA: {
    name: '트리스탄다쿠냐',
    image: <TA title="트리스탄다쿠냐" />,
    phone: '290',
  },
  TC: {
    name: '터크스 케이커스 제도',
    image: <TC title="터크스 케이커스 제도" />,
    phone: '1-649',
  },
  TD: {
    name: '차드',
    image: <TD title="차드" />,
    phone: '235',
  },
  TG: {
    name: '토고',
    image: <TG title="토고" />,
    phone: '228',
  },
  TH: {
    name: '태국',
    image: <TH title="태국" />,
    phone: '66',
  },

  TJ: {
    name: '타지키스탄',
    image: <TJ title="타지키스탄" />,
    phone: '992',
  },
  TK: {
    name: '토켈라우',
    image: <TK title="토켈라우" />,
    phone: '690',
  },
  TL: {
    name: '동티모르',
    image: <TL title="동티모르" />,
    phone: '670',
  },
  TM: {
    name: '투르크메니스탄',
    image: <TM title="투르크메니스탄" />,
    phone: '993',
  },
  TN: {
    name: '튀니지',
    image: <TN title="튀니지" />,
    phone: '216',
  },
  TO: {
    name: '통가',
    image: <TO title="통가" />,
    phone: '676',
  },
  TR: {
    name: '터키',
    image: <TR title="터키" />,
    phone: '90',
  },
  TT: {
    name: '트리니다드토바고',
    image: <TT title="트리니다드토바고" />,
    phone: '1-868',
  },
  TV: {
    name: '투발루',
    image: <TV title="투발루" />,
    phone: '688',
  },
  TW: {
    name: '대만',
    image: <TW title="대만" />,
    phone: '886',
  },
  TZ: {
    name: '탄자니아',
    image: <TZ title="탄자니아" />,
    phone: '255',
  },
  UA: {
    name: '우크라이나',
    image: <UA title="우크라이나" />,
    phone: '380',
  },
  UG: {
    name: '우간다',
    image: <UG title="우간다" />,
    phone: '256',
  },
  UM: {
    name: '미국령 군소 제도',
    image: <UM title="미국령 군소 제도" />,
    phone: '1',
  },
  US: {
    name: '미국',
    image: <US title="미국" />,
    phone: '1',
    phoneDigitLimit: 10,
    language: 'en',
  },
  UY: {
    name: '우루과이',
    image: <UY title="우루과이" />,
    phone: '598',
  },
  UZ: {
    name: '우즈베키스탄',
    image: <UZ title="우즈베키스탄" />,
    phone: '998',
  },
  VA: {
    name: '바티칸',
    image: <VA title="바티칸" />,
    phone: '379',
  },
  VC: {
    name: '세인트빈센트 그레나딘',
    image: <VC title="세인트빈센트 그레나딘" />,
    phone: '1-784',
  },

  VE: {
    name: '베네수엘라',
    image: <VE title="베네수엘라" />,
    phone: '58',
  },

  VG: {
    name: '영국령 버진아일랜드',
    image: <VG title="영국령 버진아일랜드" />,
    phone: '1-284',
  },
  VI: {
    name: '미국령 버진아일랜드',
    image: <VI title="미국령 버진아일랜드" />,
    phone: '1-340',
  },
  VN: {
    name: '베트남',
    image: <VN title="베트남" />,
    phone: '84',
  },
  VU: {
    name: '바누아투',
    image: <VU title="바누아투" />,
    phone: '678',
  },
  WF: {
    name: '왈리스 퓌티나',
    image: <WF title="왈리스 퓌티나" />,
    phone: '681',
  },
  WS: {
    name: '사모아',
    image: <WS title="사모아" />,
    phone: '685',
  },

  XK: {
    name: '코소보',
    image: <XK title="코소보" />,
    phone: '383',
  },
  YE: {
    name: '예멘',
    image: <YE title="예멘" />,
    phone: '967',
  },
  YT: {
    name: '마요트',
    image: <YT title="마요트" />,
    phone: '269',
  },
  ZA: {
    name: '남아프리카',
    image: <ZA title="남아프리카" />,
    phone: '27',
  },
  ZM: {
    name: '잠비아',
    image: <ZM title="잠비아" />,
    phone: '260',
  },
  ZW: {
    name: '짐바브웨',
    image: <ZW title="짐바브웨" />,
    phone: '263',
  },
};

// 국가별로 이름 입력 필드의 순서와 라벨을 정의합니다.
export const nameOrderMapping = {
  ko: ['lastName', 'firstName'], // 한국: 성(lastName)이 앞, 이름(firstName)이 뒤
  en: ['firstName', 'lastName'], // 영어권: 이름(firstName)이 앞, 성(lastName)이 뒤
};

export const getCountryByLanguage = () => {
  const language = localStorage.getItem('language');
  const countryEntry = Object.entries(countries).find(
    ([, countryObj]) => countryObj.language === language,
  );

  return countryEntry ? countryEntry[0] : 'KR';
};

export const formatPhoneNumberbyCountry = (phoneNumber, countryCode) => {
  if (!phoneNumber || phoneNumber === -1) {
    return '';
  }

  let formattedPhoneNumber;

  switch (countryCode) {
    case 'KR':
      if (phoneNumber.length < 3) {
        formattedPhoneNumber = phoneNumber;
      } else if (phoneNumber.length <= 7) {
        formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{1,4})/, '$1-$2');
      } else {
        formattedPhoneNumber = phoneNumber.replace(
          /(\d{3})(\d{4})(\d{1,4})/,
          '$1-$2-$3',
        );
      }
      break;

    case 'US':
      if (phoneNumber.length < 3) {
        formattedPhoneNumber = phoneNumber;
      } else if (phoneNumber.length <= 6) {
        formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{1,3})/, '$1-$2');
      } else {
        formattedPhoneNumber = phoneNumber.replace(
          /(\d{3})(\d{3})(\d{1,3})/,
          '$1-$2-$3',
        );
      }
      break;

    default:
      if (phoneNumber.length < 3) {
        formattedPhoneNumber = phoneNumber;
      } else if (phoneNumber.length <= 7) {
        formattedPhoneNumber = phoneNumber.replace(/(\d{3})(\d{1,4})/, '$1-$2');
      } else {
        formattedPhoneNumber = phoneNumber.replace(
          /(\d{3})(\d{4})(\d{1,4})/,
          '$1-$2-$3',
        );
      }
      break;
  }

  return formattedPhoneNumber;
};
