import { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { useStore } from 'src/mobx-store';
import { observer } from 'mobx-react-lite';
import { edmuSocket } from 'src/utils/socket.io';
import NetworkError from 'src/pages/exception/NetworkError';
import { AUTH_STATUS } from 'src/assets/constants';
import Infomation from 'src/pages/information/index';

const Authenticated = observer((props) => {
  const { children } = props;
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [socketState, setSocketState] = useState(edmuSocket.connected);
  const { alertStore, accountStore } = useStore();
  const { account, initializeAccount } = accountStore;
  const { setAlertOpen, setAlertClose } = alertStore;

  const handleNetworkError = () => {
    setSocketState(false);
  };

  useEffect(() => {
    setSocketState(edmuSocket.connected);
  }, [edmuSocket.connected]);

  useEffect(() => {
    edmuSocket.on('disconnect', () => {
      const timer = setTimeout(() => {
        const message = navigator.onLine
          ? 'Okomo 서버에 연결할 수 없습니다.\n사용자 네트워크 환경 문제나 방화벽 등이 문제가 될 수 있습니다.\n확인 후 이 오류가 반복되면 관리자에게 문의하세요'
          : '네트워크 연결이 원활하지 않습니다.\n인터넷 연결을 확인해주세요.';

        setAlertOpen('error', message, {
          mode: 'modal',
          closeFunc: handleNetworkError,
        });
      }, 60000);

      edmuSocket.on('connect', () => {
        clearTimeout(timer);
        setAlertClose();
        setSocketState(true);
      });
    });
  }, []);

  if (navigator.onLine) {
    if (auth.isAuthenticated) {
      if (socketState) {
        if (account.isEmailVerified !== AUTH_STATUS.VERIFIED) {
          if (
            account.isEmailVerified === AUTH_STATUS.UNVERIFIED &&
            location.pathname !== '/setting/account'
          ) {
            // 알림 모달창 출력
            setAlertOpen(
              'notice',
              '발송된 이메일의 인증코드를 확인하여 계정 인증을 진행해 주세요',
              { mode: 'modal' },
            );
            return <Navigate to={'/setting/account'} replace />;
          }
          if (account.isEmailVerified === AUTH_STATUS.EXPIRED) {
            console.log(
              '* * * Check infinite loop * Do not delete this code * * *',
            );
            initializeAccount();
            navigate(0);
          }
        }
        if (requestedLocation && location.pathname !== requestedLocation) {
          setRequestedLocation(null);
          return <Navigate to={requestedLocation} />;
        }
        return children;
      }
      return <NetworkError />;
    }

    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Infomation />;
  }

  return socketState ? children : <NetworkError />;
});

Authenticated.propTypes = {
  children: PropTypes.node,
};

export default Authenticated;
